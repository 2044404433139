import styled from 'styled-components';

export const CollapseWrapper = styled.div`
  width: 300px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  .ant-select {
    width: 100%;
  }
`;

export const ItemWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const ClearFiltersWrapper = styled.div`
  font-weight: 600;
  cursor: pointer;
  padding: 4px 8px;
  margin-top: 8px;
`;
