import { createQueryString } from '@utils';

import { IPaginatedResponse, IPagination } from '../common';
import { RequestService } from '../request';

import {
  IShareActivityTranscription,
  ITranscription,
  IUpdateTranscription
} from './transcription.contract';

export class TranscriptionService extends RequestService {
  constructor(apiUrl: string) {
    super(apiUrl);
  }

  public getTranscriptions(pagination?: IPagination, bypass?: boolean) {
    const query = createQueryString({
      limit: pagination?.limit,
      page: pagination?.page,
      bypass
    });
    return this.fetcher<IPaginatedResponse<ITranscription>>(`/transcriptions?${query}`);
  }

  public getTranscription(transcriptionId: string) {
    return this.fetcher<ITranscription>(`/transcriptions/${transcriptionId}`);
  }

  public getTranscriptionByActivity(activityId: string) {
    return this.fetcher<ITranscription>(`/transcriptions/by-activity/${activityId}`);
  }

  public generateTranscription(activityId: string) {
    return this.poster<ITranscription, undefined>(`/transcriptions/by-activity/${activityId}`);
  }

  public shareActivityTranscription(payload: IShareActivityTranscription) {
    const { activityId, ...rest } = payload;
    return this.poster(`/transcriptions/by-activity/${activityId}/share`, rest);
  }

  public updateTranscription({ activityId, transcription }: IUpdateTranscription) {
    return this.putter(`/transcriptions/by-activity/${activityId}`, transcription);
  }

  public sendToSearchIndex(activityId: string) {
    return this.poster<ITranscription, undefined>(
      `/transcriptions/by-activity/${activityId}/index`
    );
  }
}
