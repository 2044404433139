import { AnalyticsTrackEvents } from '@constants';
import { dateProvider } from '@utils';
import * as dateFns from 'date-fns';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { EventItem } from './EventItem';
import { OngoingEventItem } from './OngoingEventItem';
import { EmptyWrapper, ViewMoreWrapper, Wrapper } from './UpcomingEvents.styles';

import CLASS_ICON from '@/assets/class_icon.svg';
import { useAnalytics } from '@/hooks';
import { useWidgetNextEvents } from '@/store';

export const UpcomingEvents = () => {
  const { events } = useWidgetNextEvents();
  const { t } = useTranslation('StudentCourseSummaryTranslations');
  const navigateTo = useNavigate();
  const { fireEvent } = useAnalytics();

  const fireWidgetEvent = () =>
    fireEvent(AnalyticsTrackEvents.HOME_WIDGET, {
      widgetName: 'EVENTS'
    });

  const goToEvents = () => {
    navigateTo('/content/calendar');
    fireWidgetEvent();
  };
  const goToEvent = (eventId: string) => {
    navigateTo(`/content/calendar/${eventId}`);
    fireWidgetEvent();
  };

  const isNextEventStarted = useMemo(() => {
    if (!events || events.length === 0) return false;

    const now = dateProvider.now();
    const startDate = events[0].startDate;
    const endDate = events[0].endDate;

    const adjustedStartTime = dateProvider.subtract(
      dateProvider.zonedTimeToUtc(startDate),
      10,
      'minutes'
    );

    const endTime = dateProvider.zonedTimeToUtc(endDate);

    return dateFns.isWithinInterval(now, {
      start: adjustedStartTime,
      end: endTime
    });
  }, [events]);

  return (
    <Wrapper>
      {events && events.length > 0 ? (
        <>
          {isNextEventStarted ? (
            <OngoingEventItem events={events} />
          ) : (
            events.map(event => (
              <EventItem key={event._id} event={event} onClick={() => goToEvent(event._id)} />
            ))
          )}
          <ViewMoreWrapper onClick={goToEvents}>{t('VIEW_MORE_CLASSES')}</ViewMoreWrapper>
        </>
      ) : (
        <EmptyWrapper>
          <img src={CLASS_ICON} alt="calendar" />
          <div>{t('NO_LIVE_EVENTS')}</div>
        </EmptyWrapper>
      )}
    </Wrapper>
  );
};
