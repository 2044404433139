import styled from 'styled-components';

export const Wrapper = styled.div<{ highlighted?: string; cardselected: string }>`
  display: flex;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  width: 100%;
  cursor: pointer;
  border-radius: 8px;
  border: ${({ theme, highlighted }) =>
    highlighted ? `1px solid ${theme.colors.green6}` : undefined};

  background-color: ${({ theme, cardselected }) =>
    cardselected === 'true' ? theme.colors.white : theme.colors.black2};

  :hover {
    background-color: ${({ theme }) => theme.colors.white};
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  align-self: stretch;
  width: 100%;
`;

export const FooterWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  justify-content: space-between;
`;

export const DetailWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const TitleWrapper = styled.div`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  align-self: stretch;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 600;
`;

export const DescriptionWrapper = styled.div`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  align-self: stretch;
  overflow: hidden;
  text-overflow: ellipsis;

  & > p {
    margin: 0;
  }
`;

export const DateWrapper = styled.div`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.colors.black6};
`;
