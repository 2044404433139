import { AnalyticsTrackEvents, EUmyActions } from '@constants';
import {
  IActivity,
  IActivityTrack,
  ICreateProblemReport,
  IFile,
  ISection,
  ISubscription,
  ITrackingUpdate
} from '@sdk/contracts';
import { useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { create as createStore } from 'zustand';

import { useAnalytics, useUmy } from '@/hooks';
import {
  useMutateMySubscriptions,
  useMutateProblemReports,
  useMySubscriptionDetail,
  useProfile,
  useTranscriptionDetailByActivity
} from '@/store';
import { getActivityTrace, getNextActivityTrack } from '@/utils';

type ICurrentCourseState = {
  activity?: IActivity;
  section?: ISection;
  index: number;
};

type IStore = {
  consecutiveVideos: string[];
  setConsectuveVideos: (video: string) => void;
  isRecapModalOpen: boolean;
  setIsRecapModalOpen: (isOpen: boolean) => void;
};

const store = createStore<IStore>(set => ({
  consecutiveVideos: [],
  setConsectuveVideos: video =>
    set(state => ({ consecutiveVideos: [...state.consecutiveVideos, video] })),
  isRecapModalOpen: false,
  setIsRecapModalOpen: isOpen => set({ isRecapModalOpen: isOpen })
}));

export const useActivityTrack = () => {
  const { consecutiveVideos, setConsectuveVideos, isRecapModalOpen, setIsRecapModalOpen } = store();
  const { isLoading, subscription } = useMySubscriptionDetail();
  const { isWorking, updateActivityTrack } = useMutateMySubscriptions();
  const { reportProblem } = useMutateProblemReports();
  const { fireEvent } = useAnalytics();
  const navigateTo = useNavigate();
  const { profile } = useProfile();
  const { setType, setOnFinish } = useUmy();
  const [searchParams] = useSearchParams();
  const currentActivityId = searchParams.get('activity');
  const { transcription } = useTranscriptionDetailByActivity(currentActivityId!);

  const updateActivityTracking = (trackingData: ITrackingUpdate, onSuccess?: () => void) => {
    if (!subscription) return;
    const metadata = getActivityTrace(subscription, trackingData.activity);

    updateActivityTrack(
      {
        subscriptionId: subscription._id,
        activityTrack: { ...metadata, ...trackingData }
      },
      { onSuccess: () => onSuccess?.() }
    );
  };

  const setActivityAsCompleted = (activityId?: string) => {
    const { currentActivity } = subscription || {};

    const _id = activityId ? activityId : currentActivityId ?? currentActivity;
    if (!_id || !subscription) return;

    const { isCompleted } = getActivityTrackById(_id);
    if (isCompleted) return;

    updateActivityTracking(
      {
        activity: _id,
        isCompleted: true
      },
      () => {
        const nextActivityTrack = getNextActivityTrack(subscription, activityId);

        const { course } = subscription;
        const { sections = [] } = course;
        const section = sections.find(s => s.activities.some(a => a._id === activityId));
        const activity = section?.activities.find(a => a._id === activityId);
        fireEvent(AnalyticsTrackEvents.ACTIVITY_COMPLETED, {
          sectionName: section?.name,
          activityName: activity?.name,
          courseName: course.name,
          activityType: activity?.type
        });
        if (!nextActivityTrack) return;
        updateActivityTracking(nextActivityTrack);
      }
    );
  };

  const setActivityAsIncomplete = () => {
    const { currentActivity } = subscription || {};

    const activity = currentActivityId ?? currentActivity;
    if (!activity) return;

    updateActivityTracking({
      activity,
      isCompleted: false
    });
  };

  const getActivityTrackById = (activity: string) => {
    const { activityTracking = [] } = subscription || {};
    const currentActivityTrack = activityTracking.find(t => t.activity === activity);

    return {
      ...currentActivityTrack,
      activity
    };
  };

  const setActivityReview = (review: IActivityTrack['review']) => {
    const { currentActivity } = subscription || {};

    const activity = currentActivityId ?? currentActivity;
    if (!activity) return;

    updateActivityTracking({
      activity,
      review
    });
  };

  const sendActivityProblemReport = (
    problemReport: ICreateProblemReport,
    activity: IActivity,
    onSuccess: () => void
  ) => {
    reportProblem(problemReport, {
      onSuccess: () => {
        onSuccess();
        fireEvent(AnalyticsTrackEvents.REPORT_SENT, {
          reportType: 'activity',
          activityName: activity.name,
          reportOption: problemReport.title,
          tag: subscription?.course.tags.map(tag => tag.name),
          courseName: subscription?.course.name
        });
      }
    });
  };

  const getTimeTracking = (activityId: string) => {
    if (!subscription) return 0;

    const time = subscription.activityTracking.find(t => t.activity === activityId)?.timeTracking;

    return time ?? 0;
  };

  const updateTimeTracking = (activityId: string, time: number) => {
    updateActivityTracking({
      activity: activityId,
      timeTracking: time
    });
  };

  const getCurrentActivity = useMemo(
    () => (selectedSuscription?: ISubscription) => {
      const { course: { sections = [] } = {}, currentActivity = '' } =
        selectedSuscription ?? (subscription || {});
      const activityId = currentActivityId ?? currentActivity;

      const selectedActivity = sections.reduce((acc, section, index) => {
        const activity = section.activities.find(({ _id }) => _id === activityId);
        if (activity) {
          return {
            activity,
            section,
            index
          };
        }
        return acc;
      }, {} as ICurrentCourseState);
      if (!selectedActivity.activity && sections[0]) {
        return {
          activity: sections[0].activities[0],
          section: sections[0],
          index: 0
        };
      }
      return selectedActivity;
    },
    [currentActivityId, subscription]
  );

  const areAllActivitiesLocked = (section: ISection) => {
    return section.activities.every(
      ({ isLocked, resource }) => isLocked || (!resource?.file && !resource?.quiz)
    );
  };

  const navigateToNextActivity = () => {
    if (!subscription || !currentActivityId) return;
    const nextActivityTrack = getNextActivityTrack(subscription, currentActivityId);
    if (!nextActivityTrack) return;
    navigateTo(`/subscription/${subscription._id}?activity=${nextActivityTrack.activity}`);
  };

  const endRecapVideoTest = (score: number) => {
    setIsRecapModalOpen(false);
    if (currentActivityId)
      updateActivityTracking({ score, activity: currentActivityId }, finishRecapVideoActivity);
  };

  const skipRecapVideoTest = (file: IFile) => {
    setIsRecapModalOpen(false);
    fireEvent(AnalyticsTrackEvents.CHECK_POINT_SKIPPED, {
      videoName: file.name
    });
    finishRecapVideoActivity();
  };

  const openUmySummary = useMemo(() => {
    if (!transcription || !transcription.summary || profile?.isUmyDisabled) return false;
    return consecutiveVideos.length % 3 === 0;
  }, [consecutiveVideos, transcription]);

  const handleLaunchUmy = () => {
    setType(EUmyActions.SUMMARIZE_VIDEO);
    setOnFinish(navigateToNextActivity);
  };

  const finishRecapVideoActivity = () => {
    setActivityAsCompleted();
    if (openUmySummary) return handleLaunchUmy();
    navigateToNextActivity();
  };

  const updateIntroductionActivity = (activityId: string, isCompleted: boolean) => {
    updateActivityTracking({
      activity: activityId,
      isCompleted
    });
  };

  return {
    subscription,
    consecutiveVideos,
    isRecapModalOpen,
    openUmySummary,
    endRecapVideoActivityTest: endRecapVideoTest,
    skipActivityTest: skipRecapVideoTest,
    handleLaunchUmy,
    setIsRecapModalOpen,
    navigateToNextActivity,
    setConsectuveVideos,
    getTimeTracking,
    updateTimeTracking,
    setActivityReview,
    updateActivityTracking,
    getActivityTrackById,
    setActivityAsCompleted,
    setActivityAsIncomplete,
    sendActivityProblemReport,
    getCurrentActivity,
    areAllActivitiesLocked,
    updateIntroductionActivity,
    isLoading,
    isWorking
  };
};
