export enum ECourseStep {
  INFO = 'INFO',
  TASKS_PANEL = 'TASKS_PANEL',
  CONTENT = 'CONTENT',
  QUIZZES = 'QUIZZES',
  STUDENTS = 'STUDENTS'
}

export enum ECourseContentStep {
  INTRODUCTION = 'INTRODUCTION',
  SECTIONS = 'SECTIONS',
  MATERIAL = 'MATERIAL',
  COMPLEMENTARY_MATERIAL = 'COMPLEMENTARY_MATERIAL'
}

export enum ECourseDetailTabs {
  CONTENT = 'course-content',
  MATERIAL = 'course-material',
  COMPLEMENTARY_MATERIAL = 'complementary-material',
  INTRODUCTION = 'course-introduction',
  LIVE_RECORDS = 'live-records',
  DELIVERABLES = 'deliverables'
}
