import { IInternalNote } from '@sdk/contracts';
import { create } from 'zustand';

interface IStore {
  isOpen: boolean;
  openDrawer: (internalNote?: IInternalNote) => void;
  closeDrawer: () => void;
  internalNote?: IInternalNote;
  resetNote: () => void;
}

export const useInternalNotesForm = create<IStore>(set => ({
  isOpen: false,
  openDrawer: (internalNote?: IInternalNote) => set({ internalNote, isOpen: true }),
  closeDrawer: () => set({ isOpen: false }),
  resetNote: () => set({ internalNote: undefined })
}));
