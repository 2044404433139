import { ICreateInternalNote, IUpdateInternalNote } from '@sdk/contracts';
import { useMutation } from '@tanstack/react-query';
import { useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';

import { sdk } from '@/services';

const InternalNoteService = sdk.getService('InternalNoteService');

export const useMutateInternalNotes = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const onSuccess = () => queryClient.invalidateQueries({ queryKey: ['internal-notes'] });

  const { mutate: createInternalNote, isPending: isCreating } = useMutation({
    mutationFn: (newInternalNote: ICreateInternalNote) =>
      InternalNoteService.createInternalNote(newInternalNote),
    onSuccess: () => {
      onSuccess();
      message.success(t('CREATE_INTERNAL_NOTE_SUCCESS'));
    }
  });

  const { mutate: updateInternalNote, isPending: isUpdating } = useMutation({
    mutationFn: (newInternalNote: IUpdateInternalNote) =>
      InternalNoteService.updateInternalNote(newInternalNote),
    onSuccess: () => {
      onSuccess();
      message.success(t('UPDATE_INTERNAL_NOTE_SUCCESS'));
    }
  });

  const { mutate: deleteInternalNote, isPending: isDeleting } = useMutation({
    mutationFn: (internalNoteId: string) => InternalNoteService.deleteInternalNote(internalNoteId),
    onSuccess: () => {
      onSuccess();
      message.success(t('DELETE_INTERNAL_NOTE_SUCCESS'));
    }
  });

  return {
    createInternalNote,
    updateInternalNote,
    deleteInternalNote,
    isWorking: isCreating || isUpdating || isDeleting
  };
};
