import { AnalyticsTrackEvents } from '@constants';
import { ISubscription } from '@sdk/contracts';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import {
  EmojiWrapper,
  ProgressWrapper,
  SubscriptionInfoWrapper,
  TitleWrapper,
  Wrapper
} from './Subscriptionitem.styles';

import { useAnalytics } from '@/hooks';
import { useMySubscriptions } from '@/store';

type Props = {
  subscription: ISubscription;
};

export const SubscriptionItem: React.FC<Props> = ({ subscription }) => {
  const { setSelectedSubscription, setIsOpen, selectedSubscription } = useMySubscriptions();
  const { fireEvent } = useAnalytics();
  const { pathname } = useLocation();
  const fromHome = pathname.includes('dashboard');

  const handleSelectSubscription = () => {
    setSelectedSubscription(subscription);
    setIsOpen(false);
    fireEvent(AnalyticsTrackEvents.ACCESS_TO_COURSE, {
      fromHome
    });
  };

  const isSelected = useMemo(
    () => selectedSubscription?._id === subscription._id,
    [selectedSubscription]
  );

  return (
    <Wrapper selected={isSelected} onClick={handleSelectSubscription}>
      <EmojiWrapper>{subscription.course.emoji}</EmojiWrapper>
      <TitleWrapper>{subscription.course.name}</TitleWrapper>
      <SubscriptionInfoWrapper>
        <ProgressWrapper>{`${subscription.progress}%`}</ProgressWrapper>
      </SubscriptionInfoWrapper>
    </Wrapper>
  );
};
