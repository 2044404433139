import { Flex } from 'antd';
import styled from 'styled-components';

export const Wrapper = styled(Flex)`
  .ant-progress-text-outer {
    display: none;
  }
`;

export const ProgressTextWrapper = styled.span`
  color: ${({ theme }) => theme.colors.black9};
  font-family: ${({ theme }) => theme.fontFamily.openSans};
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
`;
