import { truncate } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { create as createStore } from 'zustand';

import { Icon } from '@/components/Icon';
import { ACTIVITY_ICONS } from '@/constants';
import { useCourseDetail } from '@/store';

interface IStore {
  courseId?: string;
  setCourseToDuplicate: (courseId?: string) => void;
}

type ITreeData = {
  key: string;
  title: string;
  children?: ITreeData[];
  icon?: React.ReactElement;
};

const store = createStore<IStore>(set => ({
  setCourseToDuplicate: (courseId?: string) => set({ courseId })
}));

export const useCourseDuplicator = () => {
  const { courseId, setCourseToDuplicate } = store();
  const { course } = useCourseDetail(courseId);

  const getCourseSections = (data: React.Key[]) => {
    const sections = new Map<string, string[]>();
    data.forEach(item => {
      const [sectionId, activityId] = item.toString().split('-');
      const section = sections.get(sectionId);
      if (section) {
        section.push(activityId);
      } else if (activityId) {
        sections.set(sectionId, [activityId]);
      }
    });
    return Array.from(sections, ([_id, activities]) => ({ _id, activities }));
  };

  const treeData: ITreeData[] = useMemo(() => {
    if (!course) return [];
    return course.sections.map(section => ({
      key: section._id,
      title: truncate(section.name, { length: 70 }),
      children: section.activities.map(activity => ({
        key: `${section._id}-${activity._id}`,
        title: truncate(activity.name, { length: 70 }),
        icon: React.createElement(Icon, { i: ACTIVITY_ICONS[activity.type] })
      }))
    }));
  }, [courseId, course]);

  const getAllKeys = useCallback((tree: typeof treeData) => {
    const result: string[] = [];
    tree.forEach(x => {
      let childKeys: string[] = [];
      if (x.children) {
        childKeys = getAllKeys(x.children);
      }
      result.push(...[x.key, ...childKeys]);
    });
    return result;
  }, []);

  const allKeys = getAllKeys(treeData);

  return {
    courseId,
    treeData,
    allKeys,
    setCourseToDuplicate,
    getCourseSections
  };
};
