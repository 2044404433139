import { IShareActivityTranscription, IUpdateTranscription } from '@sdk/contracts';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { sdk } from '@/services';

const TranscriptionService = sdk.getService('TranscriptionService');

export const useMutateTranscriptions = () => {
  const queryClient = useQueryClient();
  const onSuccess = () => queryClient.invalidateQueries({ queryKey: ['transcriptions'] });

  const { mutate: generateTranscription, isPending: isGenerating } = useMutation({
    mutationFn: (activityId: string) => TranscriptionService.generateTranscription(activityId),
    onSuccess
  });

  const { mutate: updateTranscription, isPending: isUpdating } = useMutation({
    mutationFn: (update: IUpdateTranscription) => TranscriptionService.updateTranscription(update),
    onSuccess
  });

  const { mutate: sendToSearchIndex, isPending: isSendingOne } = useMutation({
    mutationFn: (activityId: string) => TranscriptionService.sendToSearchIndex(activityId),
    onSuccess
  });

  const { mutate: shareActivityTranscription, isPending: isSharing } = useMutation({
    mutationFn: (data: IShareActivityTranscription) =>
      TranscriptionService.shareActivityTranscription(data),
    onSuccess
  });

  return {
    generateTranscription,
    updateTranscription,
    sendToSearchIndex,
    shareActivityTranscription,
    invalidateQueries: onSuccess,
    isWorking: isGenerating || isUpdating || isSendingOne || isSharing
  };
};
