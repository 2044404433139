import styled from 'styled-components';

export const Wrapper = styled.div`
  .ant-tabs {
    width: 100%;
  }

  .ant-tabs-nav {
    border-bottom: 2px solid ${({ theme }) => theme.colors.black3};
    margin: 0 0 24px 0;
  }

  .ant-tabs-content-holder {
    padding-bottom: 24px;
  }
`;
