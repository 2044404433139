import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 16px;
  height: 100%;

  .ant-collapse {
    width: 100%;
  }
`;

export const StyledUl = styled.ul`
  list-style-type: none;
  padding-left: 32px;
  margin-block-start: 0;
`;
