import { Button } from 'antd';
import styled from 'styled-components';

export const FooterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  width: 100%;
  margin-top: 24px;

  @media (min-width: ${({ theme }) => theme.mediaQueries.xs}) {
    flex-direction: row;
    gap: 24px;
  }
`;

export const StyledButton = styled(Button)`
  border-radius: 50px;
`;
