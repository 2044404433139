import { useQuery } from '@tanstack/react-query';

import { useProfile } from '../profile';

import { sdk } from '@/services';
import { getMultiplierFromStreak } from '@/utils';

const SessionLogService = sdk.getService('SessionLogService');

export const useStreak = () => {
  const { profile } = useProfile();
  const { isLoading, data } = useQuery({
    queryKey: ['session-logs', 'streak', profile?._id, profile],
    queryFn: () => SessionLogService.getUserStreak(profile!._id),
    enabled: !!profile?._id
  });

  const streak = data?.streak ?? 0;

  return {
    streak,
    multiplier: getMultiplierFromStreak(streak),
    isLoading
  };
};
