import styled from 'styled-components';

type Props = {
  disabled: boolean;
  isselected: string;
};

export const Wrapper = styled.div<Props>`
  width: 100%;

  .ant-collapse {
    display: flex;
    flex-direction: column;
    gap: 8px;
    border: 1px solid
      ${({ theme, isselected }) =>
        isselected === 'true' ? theme.colors.black6 : theme.colors.black3};

    &:hover {
      border: 1px solid
        ${({ theme, disabled }) => (disabled ? theme.colors.black3 : theme.colors.black6)};
    }
  }

  .ant-collapse-item {
    border-radius: 8px !important;
    background: ${({ theme, disabled }) => (disabled ? theme.colors.black2 : theme.colors.white)};
  }

  .ant-collapse-header {
    width: 100% !important;
    padding: 12px 16px !important;
  }

  .ant-collapse-content-box {
    padding: 0 !important;
  }
`;

export const ActivitiesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  width: 100%;
`;
