export enum EProductTourStatus {
  COMPLETED = 'COMPLETED',
  SKIPPED = 'SKIPPED',
  NOT_STARTED = 'NOT_STARTED'
}

export enum EProductTourType {
  DASHBOARD_PRODUCT_TOUR = 'dashboardProductTour',
  COURSE_PRODUCT_TOUR = 'courseProductTour'
}

export enum EProductTourTargets {
  QUIZ_MODE_WIDGET = 'QUIZ_MODE_WIDGET',
  LIVE_CLASSES_WIDGET = 'LIVE_CLASSES_WIDGET',
  EXAM_GROUPS_WIDGET = 'EXAM_GROUPS_WIDGET',
  CONTENT_WIDGET = 'CONTENT_WIDGET',
  PLANIFICATION_WIDGET = 'PLANIFICATION_WIDGET',
  PRODUCT_TOUR_ACTIONS = 'PRODUCT_TOUR_ACTIONS',
  LESSONS_TAB = 'LESSONS_TAB',
  FAVORITE_LESSONS_TAB = 'FAVORITE_LESSONS_TAB',
  SECTION_TRACKING_SELECTOR = 'SECTION_TRACKING_SELECTOR',
  SECTION_TRACKING_FILTER = 'SECTION_TRACKING_FILTER'
}
