import { ESectionTrackStatus } from '@constants';
import { create } from 'zustand';

interface IStore {
  trackingStatusFilter: ESectionTrackStatus[];
  handleTrackingStatusFilter: (status: ESectionTrackStatus) => void;
  removeTrackingStatus: (status: ESectionTrackStatus) => void;
}

export const useTrackingStatusFilter = create<IStore>(set => ({
  trackingStatusFilter: [],
  handleTrackingStatusFilter: status =>
    set(state => {
      if (state.trackingStatusFilter.includes(status)) {
        return { trackingStatusFilter: state.trackingStatusFilter.filter(s => s !== status) };
      }
      return { trackingStatusFilter: [...state.trackingStatusFilter, status] };
    }),
  removeTrackingStatus: status =>
    set(state => ({ trackingStatusFilter: state.trackingStatusFilter.filter(s => s !== status) }))
}));
