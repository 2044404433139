import styled from 'styled-components';

type Props = {
  bordercolor?: string;
  bgcolor?: string;
};

export const StyledTag = styled.div<Props>`
  border: ${({ bordercolor }) => `1px solid ${bordercolor}`};
  padding: 2px 8px;
  background-color: ${({ bgcolor }) => bgcolor};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  cursor: pointer;
  width: fit-content;
`;

export const TextWrapper = styled.div<{ textcolor?: string }>`
  color: ${({ textcolor }) => textcolor};
`;
