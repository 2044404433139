import styled from 'styled-components';

type Props = {
  $isSelected: boolean;
};

export const Wrapper = styled.div<Props>`
  width: 100%;

  .ant-collapse {
    display: flex;
    flex-direction: column;
    gap: 8px;
    border: 1px solid
      ${({ theme, $isSelected }) => ($isSelected ? theme.colors.black6 : theme.colors.black3)};

    &:hover {
      border: 1px solid ${({ theme }) => theme.colors.black6};
    }
  }

  .ant-collapse-item {
    border-radius: 8px !important;
    background: ${({ theme }) => theme.colors.white};
  }

  .ant-collapse-header {
    width: 100% !important;
    padding: 12px 16px !important;
  }

  .ant-collapse-content-box {
    padding: 0 !important;
  }
`;

export const HeaderWrapper = styled.span`
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.black9};
  font-family: ${({ theme }) => theme.fontFamily.openSans};
`;

export const ActivitiesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  width: 100%;
`;
