import { AnalyticsTrackEvents } from '@constants';
import { IFile } from '@sdk/contracts';
import { useEffect, useMemo, useState } from 'react';
import ReactPlayer from 'react-player';
import { create as createStore } from 'zustand';

import { useLocalStorage, useAnalytics } from '@/hooks';
import { useActivityTrack } from '@/store';

type IProgress = {
  played: number;
  playedSeconds: number;
  loaded: number;
  loadedSeconds: number;
};

type IStore = {
  progress?: IProgress;
  setProgress: (progress?: IProgress) => void;
  isPlaying: boolean;
  setIsPlaying: (isPlaying: boolean) => void;
  isReady: boolean;
  setIsReady: (isReady: boolean) => void;
};

const PROGRESS_TO_BE_COMPLETED = 0.99;

const store = createStore<IStore>(set => ({
  setProgress: progress => set({ progress }),
  isPlaying: false,
  setIsPlaying: isPlaying => set({ isPlaying }),
  isReady: false,
  setIsReady: isReady => set({ isReady })
}));

export const useVideoResourcePlayer = (activityId: string, subscriptionId: string) => {
  const { progress, setProgress, isPlaying, setIsPlaying, isReady, setIsReady } = store();
  const {
    getActivityTrackById,
    getCurrentActivity,
    setIsRecapModalOpen,
    handleLaunchUmy,
    openUmySummary,
    setActivityAsCompleted,
    getTimeTracking
  } = useActivityTrack();
  const { isCompleted } = getActivityTrackById(activityId!);
  const { fireEvent } = useAnalytics();
  const time = getTimeTracking(activityId);
  const { getNumber, save } = useLocalStorage();
  const { activity } = getCurrentActivity();

  const VIDEO_SEEN_KEY = useMemo(
    () => `VIDEO_SEEN_${subscriptionId}_${activityId}`,
    [subscriptionId, activityId]
  );

  const getVideoState = () => {
    return Boolean(getNumber(VIDEO_SEEN_KEY));
  };
  const [hasSeen, setHasSeen] = useState(getVideoState);

  useEffect(() => {
    setHasSeen(getVideoState());
  }, [VIDEO_SEEN_KEY]);

  const handleSetProgress = (
    file: IFile,
    courseName: string,
    sectionName: string,
    activityName: string,
    currentProgress?: IProgress
  ) => {
    if (!currentProgress) return;
    setIsPlaying(true);
    setProgress(currentProgress);
    if (currentProgress.playedSeconds > 0 && currentProgress.playedSeconds < 1 && !isCompleted) {
      fireEvent(AnalyticsTrackEvents.ACTIVITY_START_VIDEO, {
        videoNameSeen: file.name,
        courseName,
        sectionName,
        activityName
      });
    }
    if (currentProgress.played >= PROGRESS_TO_BE_COMPLETED && !hasSeen) {
      setHasSeen(true);
      fireEvent(AnalyticsTrackEvents.VIDEO_SEEN, {
        videoNameSeen: file.name,
        courseName,
        sectionName,
        activityName
      });
      save(VIDEO_SEEN_KEY, new Date().getUTCMilliseconds().toString());
    }
    if (currentProgress.played >= PROGRESS_TO_BE_COMPLETED && isPlaying) {
      if (activity && activity.questions && activity.questions.length > 0) {
        fireEvent(AnalyticsTrackEvents.CHECK_POINT_OPENED, {
          videoName: file.name
        });
        return setIsRecapModalOpen(true);
      }
      openUmySummary && handleLaunchUmy();
      setActivityAsCompleted();
    }
  };

  const handleOnReady = (p: ReactPlayer) => {
    setIsPlaying(!!p.props.playing);
    if (!isReady) {
      setIsReady(true);
      p.seekTo(time, 'seconds');
    }
  };

  return {
    progress,
    setProgress,
    handleSetProgress,
    handleOnReady
  };
};
