import { Flex } from 'antd';
import styled from 'styled-components';

export const Wrapper = styled(Flex)`
  flex-direction: column-reverse;
  gap: 24px;

  @media (min-width: ${({ theme }) => theme.mediaQueries.lg}) {
    flex-direction: row;
    position: relative;
    gap: 40px;

    & > :first-child {
      max-width: 75%;
    }

    & > :last-child {
      max-width: 25%;
      position: sticky;
      top: 24px;
      height: fit-content;
    }
  }
`;

export const TabsWrapper = styled(Flex)`
  flex-direction: column;
  gap: 12px;

  @media (min-width: 900px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const SectionsWrapper = styled(Flex)`
  height: 100%;
  width: 100%;
`;
