import { IInternalNotesFilters } from '@sdk/contracts';
import { useQuery } from '@tanstack/react-query';
import { create as createStore } from 'zustand';

import { sdk } from '@/services';

const InternalNoteService = sdk.getService('InternalNoteService');

interface IStore {
  search: string;
  filters: IInternalNotesFilters;
  setSearch: (search?: string) => void;
  setFilters: (filters?: IInternalNotesFilters) => void;
}

const store = createStore<IStore>(set => ({
  search: '',
  filters: {},
  setSearch: (search?: string) => set(state => ({ ...state, search })),
  setFilters: (filters?: IInternalNotesFilters) => set(state => ({ ...state, filters }))
}));

export const useStudentInternalNotesList = (studentId?: string) => {
  const { search, filters, setSearch, setFilters } = store();

  const { data, isLoading } = useQuery({
    queryKey: ['internal-notes', studentId, search, JSON.stringify(filters)],
    queryFn: () => InternalNoteService.findStudentInternalNotes(studentId, search, filters),
    enabled: !!studentId
  });

  const resetFilters = () => setFilters({});

  const filtersCount = Object.keys(filters).length;

  return {
    internalNotes: data,
    isLoading,
    search,
    filters,
    setSearch,
    setFilters,
    resetFilters,
    filtersCount
  };
};
