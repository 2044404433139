export const DeliverableViewerTranslations = {
  CLOSE_DELIVERABLE_PREVIEW: {
    es: 'Cerrar vista previa',
    en: 'Close deliverable preview',
    cat: 'Tancar vista prèvia',
    eus: 'Itxi aurreikuspena'
  },
  CSAT_RATING_TITLE: {
    es: '¿Estás satisfecho con la resolución de tu duda?',
    en: 'Are you satisfied with the resolution of your doubt?',
    cat: 'Estàs satisfet amb la resolució del teu dubte?',
    eus: 'Zalantzaren ebazpenarekin pozik zaude?'
  },
  TEACHER_COMMENT_PLACEHOLDER: {
    es: 'Escribe aquí tu comentario',
    en: 'Write your comment here',
    cat: 'Escriu el teu comentari aquí',
    eus: 'Ezazu zure komentario hemen'
  },
  SAVE_TEACHER_COMMENT: {
    es: 'Guardar comentario',
    en: 'Save comment',
    cat: 'Guardar comentari',
    eus: 'Gordetu komentarioa'
  },
  TEACHER_COMMENT_TITLE: {
    es: 'Comentario del profesor:',
    en: 'Teacher comment:',
    cat: 'Comentari del professor:',
    eus: 'Ikastaroko komentarioa:'
  }
};
