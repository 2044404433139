import { useTranslation } from 'react-i18next';

import { StepWrapper } from '../../styles';

import { Contract } from './components/Contract';
import { ExclamationWrapper, StarWrapper, StyledH1, Wrapper } from './ContractSign.styles';

import EXCLAMATION_VECTOR from '@/assets/exclamation_vector.svg';
import STAR_VECTOR from '@/assets/star_vector.svg';
import { useIsMobile } from '@/hooks';

export const ContractSign: React.FC = () => {
  const { t } = useTranslation('StudentCourseSummaryTranslations');
  const isMobile = useIsMobile('xs');

  return (
    <StepWrapper>
      <Wrapper>
        <StyledH1>
          <StarWrapper src={STAR_VECTOR} />
          {t('CONTRACT_TITLE')}
          {!isMobile && <ExclamationWrapper src={EXCLAMATION_VECTOR} />}
        </StyledH1>
        <div>{t('CONTRACT_SUBTITLE')}</div>
        <Contract />
      </Wrapper>
    </StepWrapper>
  );
};
