import { Flex } from 'antd';
import styled from 'styled-components';

export const Wrapper = styled(Flex)`
  height: 100%;
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const CommentTitleWrapper = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`;

export const CommentTextWrapper = styled.div`
  font-weight: 500;
  font-size: 15px;
  line-height: 26px;
`;
