import { AnalyticsTrackEvents } from '@constants';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useSignWellScript } from '../../../../hooks';

import {
  ContractSignedWrapper,
  ContractWrapper,
  FormWrapper,
  TextWrapper
} from './Contract.styles';

import PLATFORM_READY_LOTTIE from '@/assets/lotties/platform_ready.json';
import { useAnalytics, useOnboardingLogic } from '@/hooks';
import { StyledLottie } from '@/pages/Student/Onboarding/styles';
import { useMutateUserContract, useProfile } from '@/store';

export const Contract: React.FC = () => {
  const { step, isOnboardingFinished, setIsOnboardingFinished } = useOnboardingLogic();
  const { isScriptLoaded, openSignWell, closeSignWell } = useSignWellScript();
  const { t } = useTranslation('StudentCourseSummaryTranslations');
  const { generateUserContract } = useMutateUserContract();
  const { fireEvent } = useAnalytics();
  const { profile } = useProfile();

  const handleSetIsContractSigned = useCallback(() => {
    fireEvent(AnalyticsTrackEvents.CONTRACT_SIGNED, {
      chosenReason: profile?.onBoarding?.chosenReason,
      discoverySource: profile?.onBoarding?.discoverySource
    });
    setIsOnboardingFinished(true);
    closeSignWell();
  }, []);

  useEffect(() => {
    if (!isScriptLoaded || !profile || step !== 13) return;

    if (!profile?.contract?.isContractSigned && !profile?.contract?.signUrl) {
      generateUserContract(profile);
    }

    if (!profile?.contract?.isContractSigned && profile?.contract?.signUrl) {
      openSignWell(profile.contract.signUrl, {
        onComplete: handleSetIsContractSigned,
        onClose: handleSetIsContractSigned
      });
    }
  }, [step, profile?.contract?.signUrl, profile?.contract?.isContractSigned]);

  if (!profile || !profile.legalId || !profile.birthday) return null;

  return (
    <FormWrapper>
      {isOnboardingFinished ? (
        <ContractSignedWrapper>
          <StyledLottie animationData={PLATFORM_READY_LOTTIE} autoPlay loop={false} />
          <TextWrapper>{t('ALL_SET')}</TextWrapper>
          <TextWrapper>{t('YOU_CAN_START')}</TextWrapper>
        </ContractSignedWrapper>
      ) : (
        <ContractWrapper id="contract-wrapper" />
      )}
      <small>{t('CONTRACT_HELP')}</small>
    </FormWrapper>
  );
};
