import { IUser } from '@sdk/contracts';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { sdk } from '@/services';

const UserService = sdk.getService('UserService');

export const useMutateUserContract = () => {
  const queryClient = useQueryClient();
  const onSuccess = () => queryClient.invalidateQueries({ queryKey: ['profile'] });

  const { mutate: generateUserContract, isPending: isGenerating } = useMutation({
    mutationFn: (user: Partial<IUser>) => UserService.generateUserContract(user),
    onSuccess
  });

  return {
    isWorking: isGenerating,
    generateUserContract
  };
};
