import styled from 'styled-components';

type Props = {
  backgroundcolor: string;
  color: string;
};

export const Wrapper = styled.div<Props>`
  .ant-select-selector {
    border: 0 !important;
    background-color: ${({ backgroundcolor }) => backgroundcolor} !important;
    font-size: 12px !important;
    color: ${({ color }) => color} !important;
    font-family: ${({ theme }) => theme.fontFamily.openSans} !important;
    font-weight: 600 !important;
    line-height: 20px !important;
  }
`;
