import { Flex, Typography } from 'antd';
import styled from 'styled-components';

type Props = {
  $isCompleted: boolean;
};

export const Wrapper = styled(Flex)`
  width: 100%;
  padding: 12px 16px;
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.colors.black1};

    &:last-child {
      border-radius: 0 0 8px 8px;
    }
  }
`;

export const IconWrapper = styled(Flex)<Props>`
  padding: 10px;
  border-radius: 4px;
  background: ${({ theme, $isCompleted }) =>
    $isCompleted ? theme.colors.green1 : theme.colors.black2};
  pointer-events: none;
`;

export const TitleWrapper = styled(Typography.Paragraph)`
  font-family: ${({ theme }) => theme.fontFamily.openSans};
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.black9};
  margin-bottom: 0 !important;
`;

export const SubtitleWrapper = styled.span`
  font-family: ${({ theme }) => theme.fontFamily.openSans};
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.black6};
`;
