import { ConfigProvider } from 'antd';
import esES from 'antd/locale/es_ES';
import dayjs from 'dayjs';
import dayLocaleData from 'dayjs/plugin/localeData';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { useState } from 'react';
import { ThemeProvider as Provider, DefaultTheme } from 'styled-components';

import { defaultTheme } from './theme';

import 'dayjs/locale/es';

dayjs.extend(dayLocaleData);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.locale('es');

interface ThemeProviderProps {
  children: React.ReactNode;
}

export const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
  const [theme] = useState<DefaultTheme>(defaultTheme);

  return (
    <Provider theme={theme}>
      <ConfigProvider
        locale={esES}
        theme={{
          token: {
            fontFamily: theme.fontFamily.primary,
            colorPrimary: theme.colors.black9,
            colorSuccess: theme.colors.success,
            colorError: theme.colors.red6,
            colorWarning: theme.colors.orange6,
            colorInfo: theme.colors.link,
            colorBgLayout: theme.colors.white,
            purple: theme.colors.purple6,
            green: theme.colors.green6,
            red: theme.colors.red6,
            orange: theme.colors.orange6
          },
          components: {
            Alert: {
              colorInfoBg: theme.colors.purple3
            },
            Modal: {
              colorPrimary: theme.colors.black9
            },
            Button: {
              fontFamily: theme.fontFamily.primary,
              borderRadius: 100,
              borderRadiusXS: 100,
              borderRadiusSM: 100,
              borderRadiusLG: 100
            },
            Segmented: {
              trackBg: theme.colors.black2
            },
            Tag: {
              defaultBg: theme.colors.black2,
              colorBorder: theme.colors.black4,
              colorText: theme.colors.black9,
              colorError: theme.colors.red6,
              colorErrorBg: theme.colors.red2,
              colorErrorBorder: theme.colors.red6,
              colorSuccess: theme.colors.green6,
              colorSuccessBg: theme.colors.green1,
              colorSuccessBorder: theme.colors.green6,
              colorWarning: theme.colors.orange7,
              colorWarningBg: theme.colors.orange2,
              colorWarningBorder: theme.colors.orange6
            }
          }
        }}>
        {children}
      </ConfigProvider>
    </Provider>
  );
};

export default ThemeProvider;
