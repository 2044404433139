import { IGamification } from '../gamification/gamification.contract';
import { RequestService } from '../request';

export class GamificationService extends RequestService {
  constructor(apiUrl: string) {
    super(apiUrl);
  }

  public getMyRewards() {
    return this.fetcher<IGamification>(`/gamification/rewards/me`);
  }

  public getUserRewards(userId: string) {
    return this.fetcher<IGamification>(`/gamification/rewards/${userId}`);
  }
}
