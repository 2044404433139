import { Flex } from 'antd';
import styled from 'styled-components';

export const TabWrapper = styled(Flex)<{ active: string }>`
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 4px 12px;
  border-radius: 4px;
  border: 1px solid
    ${({ theme, active }) => (active === 'true' ? theme.colors.black7 : theme.colors.black4)};
  background: ${({ theme, active }) => (active === 'true' ? theme.colors.black7 : undefined)};
  cursor: pointer;

  color: ${({ theme, active }) => (active === 'true' ? theme.colors.white : theme.colors.black7)};
  font-family: ${({ theme }) => theme.fontFamily.openSans};
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;

  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.black7};
    background: ${({ theme, active }) =>
      active === 'true' ? theme.colors.black7 : theme.colors.black3};
  }
`;
