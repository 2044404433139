import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;

  .ant-form-item {
    margin-bottom: 0;
  }

  .ant-switch-checked {
    background-color: ${({ theme }) => theme.colors.green6};
  }

  .ql-toolbar.ql-snow {
    border: none;
  }
  .ql-container.ql-snow {
    border: none;
  }

  .ant-input-borderless {
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
  }
`;

export const NoteDetailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.black6};
`;
