import { Form } from 'antd';
import { useTranslation } from 'react-i18next';

import { StepWrapper } from '../../styles';

import { ChosenReasonPicker } from './ChosenReasonPicker';

import { useOnboardingLogic } from '@/hooks';

export const ChosenReason: React.FC = () => {
  const { t } = useTranslation('StudentOnboardingTranslations');
  const { handleNextStep } = useOnboardingLogic();

  return (
    <StepWrapper>
      <h2>{t('CHOSEN_REASON_TITLE')}</h2>
      <Form.Item name={['onBoarding', 'chosenReason']}>
        <ChosenReasonPicker onChange={handleNextStep} />
      </Form.Item>
    </StepWrapper>
  );
};
