import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;

export const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.black2};
  border-radius: 4px;
  padding: 16px 0px;
  flex: 1;
`;

export const ItemsWrapper = styled.div`
  display: flex;
  padding: 12px 20px 12px 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
  overflow-y: auto;
  height: 500px;

  .ant-divider-horizontal {
    margin: 0;
  }
`;

export const FiltersWrapper = styled.div`
  padding: 16px 24px 12px 24px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.black3};
`;

export const FormItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
  border: 1px solid ${({ theme }) => theme.colors.black3};
  border-radius: 4px;
  justify-content: space-between;
`;

export const FormWrapper = styled.div`
  padding: 24px;
  overflow-y: auto;
  max-height: 50vh;
`;

export const FormHeaderWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  padding: 12px 16px 12px 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.black3};
`;

export const FormFooterWrapper = styled.div`
  display: flex;
  padding: 16px;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  width: 100%;
  border-top: 1px solid ${({ theme }) => theme.colors.black3};
  align-self: flex-end;
`;
