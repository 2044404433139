import { EVerticalNames } from '@constants';
import { EFeatures } from '@constants';
import { Form, message } from 'antd';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { create } from 'zustand';

import { useIsMobile } from './useIsMobile';
import { useTagFeatures } from './useTagFeatures';

import { createSlideItems, TOTAL_SLIDES } from '@/pages/Student/Onboarding/config/slides';
import { useOnboardingForm } from '@/providers';
import { useMutatePlanification, useMySubscriptions, useProfile } from '@/store';

type IStore = {
  step: number;
  isOnboardingFinished: boolean;
  isPlatformReady: boolean;
  isRestDaysConfirmationOpen: boolean;
  setStep: (step: number) => void;
  setIsOnboardingFinished: (isOnboardingFinished: boolean) => void;
  setIsPlatformReady: (isPlatformReady: boolean) => void;
  setIsRestDaysConfirmationOpen: (isRestDaysConfirmationOpen: boolean) => void;
};

const store = create<IStore>(set => ({
  step: 0,
  isOnboardingFinished: false,
  isPlatformReady: true,
  isRestDaysConfirmationOpen: false,
  setStep: step => set({ step }),
  setIsRestDaysConfirmationOpen: isRestDaysConfirmationOpen => set({ isRestDaysConfirmationOpen }),
  setIsOnboardingFinished: isOnboardingFinished => set({ isOnboardingFinished }),
  setIsPlatformReady: isPlatformReady => set({ isPlatformReady })
}));

const NOTIFICATION_VERTICALS = [EVerticalNames.OPOS, EVerticalNames.UNI];

export const useOnboardingLogic = () => {
  const {
    step,
    isOnboardingFinished,
    isPlatformReady,
    isRestDaysConfirmationOpen,
    setStep,
    setIsOnboardingFinished,
    setIsPlatformReady,
    setIsRestDaysConfirmationOpen
  } = store();

  const { isWorking, createPlanification, removePlanification } = useMutatePlanification();
  const { t } = useTranslation('StudentOnboardingTranslations');
  const { planificableSubscriptions } = useMySubscriptions();
  const onboarding = useOnboardingForm();
  const { canRender } = useTagFeatures();
  const isMobile = useIsMobile('sm');
  const { profile, refetch } = useProfile();
  const navigateTo = useNavigate();

  const legalId = Form.useWatch(['legalId'], onboarding);
  const birthday = Form.useWatch(['birthday'], onboarding);
  const planification = Form.useWatch(['planification'], onboarding);

  const hasFinishedOnboarding = step === 13 && !!profile?.contract?.isContractSigned && !isMobile;
  const isExamDateFormEmpty =
    step === 9 &&
    !planification?.endDate &&
    (planification?.studyHours === undefined || planification?.studyMinutes === undefined);
  const isRestWeekdaysEmpty = step === 10 && !planification?.restWeekdays?.length;

  const isButtonDisabled =
    !isPlatformReady ||
    (step === 2 && !(legalId && birthday)) ||
    isExamDateFormEmpty ||
    (step === 10 && planification?.restWeekdays?.length === 7) ||
    (step === 11 && !planification?.subscriptions?.length) ||
    (step === 13 && !isOnboardingFinished && step === 13 && !profile?.contract?.isContractSigned);

  const isPlanificationEnabled = canRender(EFeatures.PLANIFICATION);

  const handleEditPlanification = () => {
    removePlanification();
    onboarding.resetFields(['planification']);
    onboarding.setFieldValue(
      ['planification', 'subscriptions'],
      planificableSubscriptions?.map(s => s._id)
    );
    setStep(8);
  };

  const handleNextStep = () => {
    if (!profile) return;
    switch (step) {
      case 0:
      case 1:
      case 8:
      case 9:
        setStep((step + 1) % TOTAL_SLIDES);
        break;
      case 5:
        onboarding.submit();
        if (!profile.tags.some(tag => NOTIFICATION_VERTICALS.includes(tag.vertical))) {
          if (isPlanificationEnabled) {
            setStep((step + 2) % TOTAL_SLIDES);
          } else {
            setStep((TOTAL_SLIDES - 2) % TOTAL_SLIDES);
          }
          break;
        }
        setStep((step + 1) % TOTAL_SLIDES);
        break;
      case 6:
        onboarding.submit();
        if (isPlanificationEnabled) {
          setStep((step + 1) % TOTAL_SLIDES);
          break;
        }
        setStep((TOTAL_SLIDES - 2) % TOTAL_SLIDES);
        break;
      case 10:
        if (isRestWeekdaysEmpty && !isRestDaysConfirmationOpen) {
          setIsRestDaysConfirmationOpen(true);
          break;
        }
        setStep((step + 1) % TOTAL_SLIDES);
        break;
      case 11:
        createPlanification(
          {
            ...planification,
            userId: profile._id,
            includeAllActivities: true,
            endDate: planification?.endDate ? new Date(planification?.endDate) : undefined,
            restDates: (planification?.restDates ?? []).map((date: Date) => new Date(date)),
            restWeekdays: planification?.restWeekdays ?? []
          },
          {
            onSuccess: () => {
              setStep((step + 1) % TOTAL_SLIDES);
            }
          }
        );
        break;
      case 13:
        refetch();
        message.success(t('WELCOME_MESSAGE'));
        navigateTo('/dashboard');

        break;
      default:
        onboarding.submit();
        setStep((step + 1) % TOTAL_SLIDES);
    }
  };

  const handleStepBack = () => setStep(step - 1);

  const slidesItems = createSlideItems({
    handleNextStep,
    planification,
    isRestDaysConfirmationOpen,
    setIsRestDaysConfirmationOpen
  });

  const slides = slidesItems.map((content, index) => ({ key: index, content }));

  const skipPlan = () => setStep((TOTAL_SLIDES - 2) % TOTAL_SLIDES);

  useEffect(() => {
    if (profile) {
      onboarding.setFieldsValue({
        ...profile,
        birthday: profile.birthday && dayjs(profile.birthday)
      });
    }
  }, [profile]);

  return {
    step,
    slides,
    onboarding,
    planification,
    isButtonDisabled,
    isOnboardingFinished,
    hasFinishedOnboarding,
    isPlatformReady,
    isRestDaysConfirmationOpen,
    setStep,
    handleNextStep,
    handleStepBack,
    setIsOnboardingFinished,
    setIsPlatformReady,
    setIsRestDaysConfirmationOpen,
    handleEditPlanification,
    skipPlan,
    isWorking
  };
};
