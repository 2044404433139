import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;

  @media (min-width: ${({ theme }) => theme.mediaQueries.xs}) {
    border-left: 1px solid ${({ theme }) => theme.colors.black4};
  }
`;
