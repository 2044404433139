import { AnalyticsTrackEvents, ERoles } from '@constants';
import { IUser } from '@sdk/contracts';
import { useQuery } from '@tanstack/react-query';
import { useUnleashContext } from '@unleash/proxy-client-react';
import { parseCookies } from '@utils';
import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { useCurrentStudentTutorDetail } from '../tutoring-groups';

import { useMutateProfile } from '.';

import { useAnalytics, useSocket } from '@/hooks';
import { sdk } from '@/services';
import { checkUserRole } from '@/utils';

const UserService = sdk.getService('UserService');

export const useProfile = () => {
  const { isWorking, updateProfile: mutateProfile } = useMutateProfile();
  const { tutor } = useCurrentStudentTutorDetail();
  const { identify, fireEvent } = useAnalytics();
  const updateContext = useUnleashContext();
  const navigateTo = useNavigate();
  const { token } = parseCookies();
  const { connect } = useSocket();

  const {
    isLoading,
    data: profile,
    refetch
  } = useQuery({
    queryKey: ['profile', token],
    queryFn: () => UserService.getMe(),
    enabled: !!token,
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: false
  });

  useEffect(() => {
    if (profile) {
      updateContext({ userId: profile._id });
      connect(profile);
    }
  }, [profile]);

  const roleCheck = checkUserRole(profile);

  const moreInfo = () => UserService.moreInfo();

  const updateProfile = async (updatedUser: Partial<IUser>, onSuccess?: () => void) => {
    mutateProfile(updatedUser, {
      onSuccess: user => {
        onSuccess?.();
        const { name, lastName, email } = user;
        identify(user);
        fireEvent(AnalyticsTrackEvents.PROFILE_UPDATED, {
          name,
          email,
          lastName
        });
      }
    });
  };

  const isOnboardingCompleted = useMemo(
    () =>
      profile?.contract?.signatureRequestId ||
      profile?.role !== ERoles.STUDENT ||
      profile?.contract?.isContractSigned,
    [profile?.contract]
  );

  const { isStudent } = roleCheck;

  useEffect(() => {
    !isLoading && !isOnboardingCompleted && navigateTo('/onboarding');
  }, [isStudent, isOnboardingCompleted]);

  return {
    isLoading,
    isWorking,
    profile,
    refetch,
    updateProfile,
    roleCheck,
    moreInfo,
    tutor,
    isOnboardingCompleted
  };
};
