import { createQueryString } from '@utils';

import { RequestService } from '../request';

import {
  IAddEventAttachment,
  IAddEventLiveRecord,
  IAddRemoveAttendee,
  IBasicEvent,
  ICreateEvent,
  IEvent,
  IEventByType,
  IEventsFilters,
  IGroupedLiveRecords,
  IRemoveEventAttachment,
  IRemoveEventLiveRecord,
  ISendEventMessage,
  IUpdateEvent,
  IUpdateEventLiveRecordName
} from './event.contract';

export class EventService extends RequestService {
  constructor(apiUrl: string) {
    super(apiUrl);
  }

  public getAllEvents(filters: IEventsFilters) {
    const query = createQueryString({ filters });
    return this.fetcher<IEventByType[]>(`/events?${query}`);
  }

  public getEvent(eventId: string) {
    return this.fetcher<IEvent>(`/events/${eventId}`);
  }

  public getEventsWidgetNextEvents() {
    return this.fetcher<IEvent[]>('/events/widget/next-events');
  }

  public createEvent(newEvent: ICreateEvent) {
    return this.poster<IEvent, ICreateEvent>('/events', newEvent);
  }

  public updateEvent(updatedEvent: IUpdateEvent) {
    return this.putter<IEvent, IUpdateEvent>(`/events/${updatedEvent._id}`, updatedEvent);
  }

  public addAttendee({ eventId, attendeeId }: IAddRemoveAttendee) {
    return this.putter<IEvent, undefined>(`/events/${eventId}/attendees/${attendeeId}`);
  }

  public removeAttendee({ eventId, attendeeId }: IAddRemoveAttendee) {
    return this.deleter<IEvent>(`/events/${eventId}/attendees/${attendeeId}`);
  }

  public removeEvent(eventId: string, removeRecurring: boolean) {
    const query = createQueryString({ filters: removeRecurring ? { removeRecurring } : {} });
    return this.deleter(`/events/${eventId}?${query}`);
  }

  public sendMessage({ eventId, message }: ISendEventMessage) {
    return this.poster(`/events/${eventId}/messages`, { content: message });
  }

  public removeAttachment({ eventId, attachmentId }: IRemoveEventAttachment) {
    return this.deleter(`/events/${eventId}/attachments/${attachmentId}`);
  }

  public addAttachment({ eventId, file }: IAddEventAttachment) {
    return this.poster(`/events/${eventId}/attachments`, { attachment: file });
  }

  public removeLiveRecord({ eventId, liveRecordId }: IRemoveEventLiveRecord) {
    return this.deleter(`/events/${eventId}/live-records/${liveRecordId}`);
  }

  public addLiveRecord({ eventId, file }: IAddEventLiveRecord) {
    return this.poster(`/events/${eventId}/live-records`, { liveRecord: file });
  }

  public getTodayEvents() {
    return this.fetcher<IBasicEvent[]>('/events/today');
  }

  public findAllStudentLiveRecordsByCourse(courseId: string) {
    return this.fetcher<IGroupedLiveRecords[]>(`/events/live-records/${courseId}`);
  }

  public updateLiveRecordName({ eventId, liveRecordId, newName }: IUpdateEventLiveRecordName) {
    return this.putter(`/events/${eventId}/live-records/${liveRecordId}/name`, { newName });
  }
}
