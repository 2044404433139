export enum AnalyticsTrackEvents {
  PAGE_VIEW = '$pageview',
  PAGE_LEAVE = '$pageleave',
  ONBOARDING_ENDED = 'ONBOARDING_ENDED',
  CONTRACT_SIGNED = 'CONTRACT_SIGNED',
  ONBOARDING_FORWARD = 'ONBOARDING_FORWARD',
  PREMIUM_CONVERSION = 'PREMIUM_CONVERSION',
  CONVERSION_INTEREST = 'CONVERSION_INTEREST',
  BLOCKED_FUNCTION = 'BLOCKED_FUNCTION',
  VIDEO_SEEN = 'VIDEO_SEEN',
  ACTIVITY_MATERIAL_DOWNLOAD = 'ACTIVITY_MATERIAL_DOWNLOAD',
  ACTIVITY_NEW_DOUBT = 'ACTIVITY_NEW_DOUBT',
  ACTIVITY_LATERAL_SECTION = 'ACTIVITY_LATERAL_SECTION',
  ACTIVITY_DELIVERABLE_UPLOAD = 'ACTIVITY_DELIVERABLE_UPLOAD',
  PROFILE_UPDATED = 'PROFILE_UPDATED',
  STATISTICS_FILTER = 'STATISTICS_FILTER',
  STATISTICS_DETAIL_FILTER = 'STATISTICS_DETAIL_FILTER',
  QUIZ_REPEAT = 'QUIZ_REPEAT',
  QUIZ_RESULTS_VIEWED = 'QUIZ_RESULTS_VIEWED',
  QUIZ_CREATED = 'QUIZ_CREATED',
  QUIZ_ANSWERS_SENT = 'QUIZ_ANSWERS_SENT',
  DELIVERABLES_SEARCH = 'DELIVERABLES_SEARCH',
  DELIVERABLES_CONSULT = 'DELIVERABLES_CONSULT',
  DELIVERABLE_UPLOADED = 'DELIVERABLE_UPLOADED',
  DELIVERABLE_RATE = 'DELIVERABLE_RATE',
  DELIVERABLE_DELETED = 'DELIVERABLE_DELETED',
  MESSAGE_SENT = 'MESSAGE_SENT',
  DOUBTS_CONSULT = 'DOUBTS_CONSULT',
  NEW_DOUBT = 'NEW_DOUBT',
  DOUBTS_CLOSED = 'DOUBTS_CLOSED',
  DOUBTS_REPLY = 'DOUBTS_REPLY',
  DOUBT_VOTE = 'DOUBT_VOTE',
  EVENTS_ASSIST = 'EVENTS_ASSIST',
  EVENTS_NO_ASSIST = 'EVENTS_NO_ASSIST',
  EVENTS_CONSULT = 'EVENTS_CONSULT',
  REPORT_SENT = 'REPORT_SENT',
  PAYMENT_ATTEMPTED = 'PAYMENT_ATTEMPTED',
  PAYMENT_ERROR = 'PAYMENT_ERROR',
  ACTIVITY_SHARE = 'ACTIVITY_SHARE',
  TEACHER_SECTION_NEW = 'TEACHER_SECTION_NEW',
  TEACHER_ACTIVITY_NEW = 'TEACHER_ACTIVITY_NEW',
  TEACHER_ACTIVITY_SEEN = 'TEACHER_ACTIVITY_SEEN',
  TEACHER_ACTIVITY_EDITED = 'TEACHER_ACTIVITY_EDITED',
  CHECK_POINT_OPENED = 'CHECK_POINT_OPENED',
  CHECK_POINT_SKIPPED = 'CHECK_POINT_SKIPPED',
  CHECK_POINT_ANSWERS = 'CHECK_POINT_ANSWERS',
  RATING = 'RATING',
  REVISION_STARTED = 'REVISION_STARTED',
  REVISION_SKIPPED = 'REVISION_SKIPPED',
  REVISION_ENDED = 'REVISION_ENDED',
  COMMUNITY_ACCESS = 'COMMUNITY_ACCESS',
  EXAM_GROUPS_FILES = 'EXAM_GROUPS_FILES',
  EXAM_GROUPS_LIST = 'EXAM_GROUPS_LIST',
  EXAM_GROUPS_JOINED = 'EXAM_GROUPS_JOINED',
  EXAM_GROUPS_LEAVED = 'EXAM_GROUPS_LEAVED',
  STUDY_STATUS_DASHBOARD = 'STUDY_STATUS_DASHBOARD',
  MATERIAL_DOWNLOADED = 'MATERIAL_DOWNLOADED',
  ACTIVITY_COMPLETED = 'ACTIVITY_COMPLETED',
  ACTIVITY_START_VIDEO = 'ACTIVITY_START_VIDEO',
  MATERIAL_OPENED = 'MATERIAL_OPENED',
  MENU_ACCESS = 'MENU_ACCESS',
  UMY_TASK_POINTS = 'UMY_TASK_POINTS',
  EXAM_GROUPS_ACCESS_HOME = 'EXAM_GROUPS_ACCESS_HOME',
  TASK_APPROVED = 'TASK_APPROVED',
  TASK_REVIEW = 'TASK_REVIEW',
  TASK_REJECTED = 'TASK_REJECTED',
  HOME_WIDGET = 'HOME_WIDGET',
  PLAN_CREATED = 'PLAN_CREATED',
  ACCESS_TO_COURSE = 'ACCESS_TO_COURSE',
  TASK_CREATED = 'TASK_CREATED',
  COURSE_DELETED = 'COURSE_DELETED',
  EXAM_SCORE = 'EXAM_SCORE',
  RECORDED_CLASS_SELECTED = 'RECORDED_CLASS_SELECTED',
  FAVOURITE_MARKED = 'FAVOURITE_MARKED',
  NPS_VOTE = 'NPS_VOTE',
  PLAN_ACTIVITY = 'PLAN_ACTIVITY',
  RATING_CLASS = 'RATING_CLASS',
  EVENT_NEW = 'EVENT_NEW',
  EVENT_DELETED = 'EVENT_DELETED'
}
