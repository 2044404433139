import { AnalyticsTrackEvents, EQuizMode } from '@constants';
import { Button, Modal } from 'antd';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, LinearScale } from 'chart.js';
import { useMemo } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { QuizScoreDetail } from '../Quiz';

import { LottieClock, SpentTimer } from './components';
import {
  AnswersContentWrapper,
  AnswersTitleWrapper,
  AnswersWrapper,
  ButtonsWrapper,
  ColumnWrapper,
  ContentWrapper,
  DoughnutWrapper,
  ItemWrapper,
  ScoreTimeWrapper,
  ScoreTitleWrapper,
  ScoreWrapper,
  StatsWrapper,
  StyledTag,
  TagsTitleWrapper,
  TagsWrapper,
  TagTextWrapper,
  TagWrapper,
  TitleWrapper,
  TotalQuestionsWrapper,
  TotalWrapper,
  Wrapper
} from './QuizResults.styles';

import { useIsMobile, useAnalytics } from '@/hooks';
import { useMyQuizDetail, useLastQuizResultByQuizId, useProfile } from '@/store';

ChartJS.register(ArcElement, Tooltip, Legend, LinearScale);

const CHART_OPTIONS = {
  responsive: true,
  plugins: {
    legend: {
      display: false
    }
  },
  cutout: '80%',
  borderRadius: 10
};

const CHART_SIZE = 146;
const MODAL_WIDTH = 588;

export const QuizResults = () => {
  const { t } = useTranslation('QuizResultsTranslations');
  const { quiz: quizDetail } = useMyQuizDetail();
  const { _id: quizId } = useParams<{ _id: string }>();
  const [searchParams, setSearchParams] = useSearchParams();
  const activity = searchParams.get('activity');
  const subscription = searchParams.get('subscription');
  const results = searchParams.get('results');
  const { quizResult } = useLastQuizResultByQuizId(quizId);
  const navigateTo = useNavigate();
  const { colors } = useTheme();
  const isMobile = useIsMobile();
  const { fireEvent } = useAnalytics();
  const {
    roleCheck: { isManager }
  } = useProfile();

  const isFromSubscription = !!(activity && subscription);
  const isPracticeMode = useMemo(
    () => quizResult?.quiz.mode === EQuizMode.PRACTICE_MODE && !isManager,
    [quizResult]
  );

  const statsData = useMemo(() => {
    if (!quizResult) return [];
    const { quiz, correctResponses, wrongResponses, blankResponses } = quizResult;
    const totalQuestions = quiz.questions.length;

    return [
      {
        label: t('CORRECT_RESPONSES'),
        value: correctResponses.length,
        percentage: (correctResponses.length / totalQuestions) * 100,
        color: colors.green6,
        backgroundColor: colors.green1
      },
      {
        label: t('WRONG_RESPONSES'),
        value: wrongResponses.length,
        percentage: (wrongResponses.length / totalQuestions) * 100,
        color: colors.red6,
        backgroundColor: colors.red2
      },
      {
        label: t('BLANK_RESPONSES'),
        value: blankResponses.length,
        percentage: (blankResponses.length / totalQuestions) * 100,
        color: colors.black7,
        backgroundColor: colors.black2
      }
    ];
  }, [quizResult]);

  if (!quizResult) return null;

  const goBackBack = () => {
    if (isFromSubscription)
      return navigateTo(`/subscription/${subscription}?activity=${activity}`, {
        replace: true
      });

    navigateTo(isPracticeMode ? '/tests/practice-mode' : '/tests', {
      replace: true
    });
  };

  const goToReview = () => {
    searchParams.delete('results');
    setSearchParams(searchParams);
    fireEvent(AnalyticsTrackEvents.QUIZ_RESULTS_VIEWED, { quizName: quizResult.quiz.title });
  };

  const { score, quiz, spentTime, correctResponses, wrongResponses, blankResponses } = quizResult;
  const { tags = [], questions = [] } = quiz;

  const chartData = {
    labels: [t('CORRECT_RESPONSES'), t('WRONG_RESPONSES'), t('BLANK_RESPONSES')],
    datasets: [
      {
        data: [correctResponses.length, wrongResponses.length, blankResponses.length],
        backgroundColor: [colors.green6, colors.red6, colors.black4]
      }
    ]
  };

  return (
    <Modal
      centered
      footer={null}
      width={!isMobile ? MODAL_WIDTH : undefined}
      closable={!quizDetail}
      open={!!results}
      onCancel={!quizDetail ? goBackBack : undefined}
      title={<TitleWrapper>{t('RESULTS')}</TitleWrapper>}>
      <Wrapper>
        <LottieClock />
        <ContentWrapper>
          <DoughnutWrapper>
            {!isMobile && (
              <Doughnut
                options={CHART_OPTIONS}
                data={chartData}
                style={{ height: CHART_SIZE, width: CHART_SIZE, margin: '8px 0px' }}
              />
            )}
            <TotalQuestionsWrapper>
              <Trans
                i18nKey="QUESTIONS_TOTAL"
                components={{ a: <TotalWrapper /> }}
                values={{ total: questions.length }}
              />
            </TotalQuestionsWrapper>
          </DoughnutWrapper>
          <ColumnWrapper>
            <StatsWrapper>
              <ScoreTimeWrapper>
                <ScoreWrapper>
                  <ScoreTitleWrapper>{t('SCORE')}</ScoreTitleWrapper>
                  <QuizScoreDetail score={score} />
                </ScoreWrapper>
                {!isMobile && <SpentTimer spentTime={spentTime} />}
              </ScoreTimeWrapper>
              <AnswersWrapper>
                {statsData.map(({ label, value, percentage, color, backgroundColor }) => (
                  <ItemWrapper key={label}>
                    <div>
                      <Trans
                        i18nKey="ANSWERS_TOTAL"
                        components={{ a: <AnswersTitleWrapper />, b: <AnswersContentWrapper /> }}
                        values={{ answer: label, total: value }}
                      />
                    </div>
                    <StyledTag backgroundColor={backgroundColor}>
                      <TagTextWrapper color={color}>{`${percentage?.toFixed(2)}%`}</TagTextWrapper>
                    </StyledTag>
                  </ItemWrapper>
                ))}
              </AnswersWrapper>
              <TagsWrapper>
                <TagsTitleWrapper>{t('CONTENT')}</TagsTitleWrapper>
                {tags.length > 3 ? (
                  <>
                    {tags.slice(0, 2).map(tag => (
                      <TagWrapper key={tag._id}>{tag.name}</TagWrapper>
                    ))}
                    <TagWrapper>{`+${tags.length - 2}`}</TagWrapper>
                  </>
                ) : (
                  tags.map(tag => <TagWrapper key={tag._id}>{tag.name}</TagWrapper>)
                )}
              </TagsWrapper>
            </StatsWrapper>
          </ColumnWrapper>
        </ContentWrapper>
        <ButtonsWrapper>
          {isFromSubscription ? (
            <>
              <Button block={isMobile} onClick={goToReview}>
                {t('REVIEW_RESULTS')}
              </Button>
              <Button block={isMobile} onClick={goBackBack} type="primary">
                {t('RETURN_TO_ACTIVITY')}
              </Button>
            </>
          ) : (
            <>
              <Button block={isMobile} onClick={goToReview} type="primary">
                {t('REVIEW_RESULTS')}
              </Button>
              <Button block={isMobile} onClick={goBackBack}>
                {t('EXIT')}
              </Button>
            </>
          )}
        </ButtonsWrapper>
      </Wrapper>
    </Modal>
  );
};
