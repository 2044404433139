import { createQueryString } from '@utils';

import { IPaginatedResponse, IPagination } from '../common';
import { RequestService } from '../request';

import { IScrappedExam, IScrappedExamsFilter } from './scrapped-exam.contract';

export class ScrappedExamService extends RequestService {
  constructor(apiUrl: string) {
    super(apiUrl);
  }

  async getScrappedExams(
    search?: string,
    pagination?: IPagination,
    filters?: IScrappedExamsFilter
  ) {
    const query = createQueryString({
      search,
      page: pagination?.page,
      limit: pagination?.limit,
      filters
    });
    return this.fetcher<IPaginatedResponse<IScrappedExam>>(`/scrapped-exams?${query}`);
  }

  async findAllScrappedExams() {
    return this.fetcher<IScrappedExam[]>('/scrapped-exams/all');
  }
}
