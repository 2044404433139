import { AnalyticsTrackEvents } from '@constants';
import { IPlanification, IUser } from '@sdk/contracts';
import mixpanel from 'mixpanel-browser';
import posthog from 'posthog-js';
import { useEffect, useRef } from 'react';

import { getHowIsPlanificationGoing } from '@/utils';

type IProperties = Record<
  string,
  string | number | boolean | null | undefined | string[] | number[] | Date
>;

export const useAnalytics = () => {
  const initializedRef = useRef(false);

  useEffect(() => {
    if (!initializedRef.current) {
      mixpanel.init(import.meta.env.VITE_MIXPANEL_TOKEN, {
        debug: import.meta.env.MODE === 'development'
      });
      posthog.init(import.meta.env.VITE_POSTHOG_TOKEN, {
        api_host: import.meta.env.VITE_POSTHOG_HOST || 'https://eu.i.posthog.com',
        person_profiles: 'identified_only'
      });
      initializedRef.current = true;
    }
  }, []);

  const fireEvent = (event: AnalyticsTrackEvents, properties?: IProperties) => {
    if (!initializedRef.current) return;
    mixpanel.track(event, properties);
    posthog.capture(event, properties);
  };

  const identify = (user: IUser, planification?: IPlanification, utm?: (string | null)[]) => {
    if (!initializedRef.current) return;
    const { _id, name, email, lastName, tags, createdAt, role } = user;
    mixpanel.identify(_id);
    const properties = {
      name,
      email,
      fullName: `${name} ${lastName}`,
      $name: name,
      $email: email,
      lastName,
      role,
      utm,
      $created: createdAt,
      tags: tags.map(tag => tag.name),
      vertical: tags.map(tag => tag.vertical),
      isInternal: email.includes('ucademy.com'),
      planConsecution: planification
        ? getHowIsPlanificationGoing(planification)?.completionPercentage.toFixed(2)
        : 0
    };
    mixpanel.people.set(properties);
    posthog.identify(_id, properties);
  };

  const init = (user: IUser, planification?: IPlanification, utm?: (string | null)[]) => {
    if (!initializedRef.current) {
      mixpanel.init(import.meta.env.VITE_MIXPANEL_TOKEN, { debug: true });
      posthog.init(import.meta.env.VITE_POSTHOG_TOKEN, {
        api_host: import.meta.env.VITE_POSTHOG_HOST || 'https://eu.i.posthog.com',
        person_profiles: 'identified_only'
      });
      initializedRef.current = true;
    }
    if (user) {
      identify(user, planification, utm);
    }
  };

  const reset = () => {
    posthog.reset();
  };
  const pageLeave = () => {
    posthog.capture('$pageleave');
  };

  return { init, fireEvent, identify, pageLeave, reset };
};
