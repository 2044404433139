import { Divider, Flex, Typography } from 'antd';
import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;

  .ant-collapse {
    display: flex;
    flex-direction: column;
    gap: 8px;
    border: 1px solid ${({ theme }) => theme.colors.black4};
  }

  .ant-collapse-item {
    padding: 16px !important;
    border-radius: 8px !important;
  }

  .ant-collapse-header {
    width: 100% !important;
    padding: 0 !important;
  }

  .ant-collapse-content-box {
    padding: 8px 0px 0px 0px !important;
  }

  .ant-collapse-expand-icon {
    position: absolute !important;
    right: 0 !important;
  }
`;

export const HeaderWrapper = styled(Flex)`
  width: 100%;
`;

export const TitleWrapper = styled(Typography.Text)`
  color: ${({ theme }) => theme.colors.black9};
  font-family: ${({ theme }) => theme.fontFamily.openSans};
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
`;

export const SectionsCountWrapper = styled(Typography.Text)`
  color: ${({ theme }) => theme.colors.black6};
  font-family: ${({ theme }) => theme.fontFamily.openSans};
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
`;

export const DividerWrapper = styled(Divider)`
  height: 24px !important;
  border-color: ${({ theme }) => theme.colors.black4} !important;
  margin-inline: 0 !important;
`;

export const SectionsTrackWrapper = styled(Flex)`
  flex-direction: column;
  gap: 8px;

  @media (min-width: 900px) {
    flex-direction: row;
  }

  @media (min-width: ${({ theme }) => theme.mediaQueries.lg}) {
    flex-direction: column;
    gap: 12px;
  }
`;

type Props = {
  isselected: string;
};

export const SectionTrackStatusWrapper = styled(Flex)<Props>`
  border-radius: 8px;
  border: 1px solid
    ${({ theme, isselected }) =>
      isselected === 'true' ? theme.colors.black6 : theme.colors.black4};
  padding: 12px;
  cursor: pointer;
  transition: all 0.3s ease;
  background: ${({ theme, isselected }) => isselected === 'true' && theme.colors.black3};

  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.black6};
  }
`;

export const IconWrapper = styled.div<{ background: string }>`
  width: 12px;
  height: 12px;
  border-radius: 2px;
  background: ${({ background }) => background};
`;

export const TextWrapper = styled.span<Props>`
  color: ${({ theme, isselected }) =>
    isselected === 'true' ? theme.colors.black9 : theme.colors.black7};
  font-family: ${({ theme }) => theme.fontFamily.openSans};
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
`;

export const CountWrapper = styled.span`
  color: ${({ theme }) => theme.colors.black9};
  font-family: ${({ theme }) => theme.fontFamily.openSans};
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
`;
