import { AnalyticsTrackEvents, ENpsVoteType } from '@constants';
import { ICreateNpsVote } from '@sdk/contracts';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useAnalytics } from '@/hooks';
import { sdk } from '@/services';

const NpsVoteService = sdk.getService('NpsVoteService');

export const useMutateNpsVote = () => {
  const queryClient = useQueryClient();
  const { fireEvent } = useAnalytics();

  const onSuccess = () => queryClient.invalidateQueries({ queryKey: ['nps-vote'] });

  const { mutate: createNpsVote, isPending: isCreating } = useMutation({
    mutationFn: (create: ICreateNpsVote) => NpsVoteService.createNpsVote(create),
    onSuccess: npsVote => {
      onSuccess();
      fireEvent(AnalyticsTrackEvents.NPS_VOTE, {
        onboarding: npsVote.type === ENpsVoteType.ONBOARDING
      });
    }
  });

  return {
    createNpsVote,
    isCreating
  };
};
