import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  width: 100%;
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  justify-content: space-between;
  width: 100%;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  width: 100%;
  justify-content: space-between;
`;

export const HighlightedNotesWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  height: 32px;
  border: 1px solid ${({ theme }) => theme.colors.black9};
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 100px;
  padding: 4px 16px;
  cursor: pointer;
  font-weight: 600;

  :hover {
    border-color: ${({ theme }) => theme.colors.green7};
    color: ${({ theme }) => theme.colors.green7};
  }
`;

export const CloseHighlightedNotesWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  width: 100%;
`;

export const BoldWrapper = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.secondary};
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.2px;
`;
