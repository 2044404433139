export enum EExamGroupsType {
  CONTEST = 'CONTEST',
  CONTEST_OPPOSITION = 'CONTEST_OPPOSITION',
  OPPOSITION = 'OPPOSITION',
  ORDINARY = 'ORDINARY',
  EXTRAORDINARY = 'EXTRAORDINARY',
  JOB_BOARD = 'JOB_BOARD'
}

export const EXAM_TYPES = Object.values(EExamGroupsType);
