import styled from 'styled-components';

export const EmptyTypeWrapper = styled.div`
  display: flex;
  padding: 0px 8px 0px 6px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 100px;
  border: 1px solid ${({ theme }) => theme.colors.purple6};
  width: fit-content;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.purple6};
  font-weight: 600;

  :hover {
    border: 1px solid ${({ theme }) => theme.colors.purple7};
    background-color: ${({ theme }) => theme.colors.purple2};
  }
`;
