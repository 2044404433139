import { AnalyticsTrackEvents, ECourseStatus } from '@constants';
import {
  IAssignTeacherToCourse,
  ICourse,
  ICourseToDuplicate,
  ICreateCourse,
  ICreateFolder,
  ICreateSection,
  IFile,
  IFoldersWithMaterial,
  IMoveMaterial,
  IRemoveFolder,
  IReorderActivities,
  IReorderFolders,
  IReorderMaterial,
  IReorderSections,
  IUpdateComplementaryMaterialTitle,
  IUpdateFolder,
  IUpdateMaterialName
} from '@sdk/contracts';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';

import { useAnalytics } from '@/hooks';
import { sdk } from '@/services';

const CourseService = sdk.getService('CourseService');

export const useMutateCourses = () => {
  const queryClient = useQueryClient();
  const onSuccess = () => queryClient.invalidateQueries({ queryKey: ['courses'] });
  const { t } = useTranslation('CourseTranslations');
  const { fireEvent } = useAnalytics();

  const { mutate: createCourse, isPending: isCreating } = useMutation({
    mutationFn: (newCourse: ICreateCourse) => CourseService.createCourse(newCourse),
    onSuccess: () => {
      onSuccess();
      message.success(t('CREATE_COURSE_SUCCESS'));
    }
  });

  const { mutate: updateCourse, isPending: isUpdating } = useMutation({
    mutationFn: (course: Partial<ICourse>) => CourseService.updateCourse(course),
    onSuccess: () => {
      onSuccess();
      message.success(t('UPDATE_COURSE_SUCCESS'));
    }
  });

  const { mutate: removeCourse, isPending: isRemoving } = useMutation({
    mutationFn: (courseId: string) => CourseService.removeCourse(courseId),
    onSuccess: () => {
      onSuccess();
      message.warning(t('DELETE_COURSE_SUCCESS'));
      fireEvent(AnalyticsTrackEvents.COURSE_DELETED);
    }
  });

  const { mutate: reorderCourseSections, isPending: isReorderingSections } = useMutation({
    mutationFn: (reorderSections: IReorderSections) =>
      CourseService.reorderCourseSections(reorderSections),
    onSuccess
  });

  const { mutate: reorderCourseActivities, isPending: isReorderingActivities } = useMutation({
    mutationFn: (reorderActivities: IReorderActivities) =>
      CourseService.reorderSectionActivities(reorderActivities),
    onSuccess
  });

  const { mutate: reorderCourseFolders, isPending: isReorderingFolders } = useMutation({
    mutationFn: (reorderFolders: IReorderFolders) =>
      CourseService.reorderCourseFolders(reorderFolders),
    onSuccess
  });

  const { mutate: reorderCourseMaterial, isPending: isReorderingMaterial } = useMutation({
    mutationFn: (reorderMaterial: IReorderMaterial) =>
      CourseService.reorderFolderMaterial(reorderMaterial),
    onSuccess
  });

  const { mutate: moveMaterialBetweenFolders, isPending: isMovingMaterial } = useMutation({
    mutationFn: (moveMaterial: IMoveMaterial) =>
      CourseService.moveMaterialBetweenFolders(moveMaterial),
    onSuccess: () => {
      onSuccess();
      message.success(t('ELEMENTS_MOVED_SUCCESS'));
    }
  });

  const { mutate: updateMaterialName, isPending: isUpdatingMaterialName } = useMutation({
    mutationFn: (updateMaterial: IUpdateMaterialName) =>
      CourseService.updateMaterialNameInFolder(updateMaterial),
    onSuccess: () => {
      onSuccess();
      message.success(t('MATERIAL_UPDATED'));
    }
  });

  const { mutate: createFolder, isPending: isCreatingFolder } = useMutation({
    mutationFn: (folder: ICreateFolder) => CourseService.createFolder(folder),
    onSuccess: () => {
      onSuccess();
      message.success(t('SECTION_CREATED'));
    }
  });

  const { mutate: updateFolder, isPending: isUpdatingFolder } = useMutation({
    mutationFn: (data: IUpdateFolder) => CourseService.updateFolder(data),
    onSuccess: () => {
      onSuccess();
      message.success(t('SECTION_UPDATED'));
    }
  });

  const { mutate: removeFolder, isPending: isRemovingFolder } = useMutation({
    mutationFn: (remove: IRemoveFolder) => CourseService.removeFolder(remove),
    onSuccess: () => {
      onSuccess();
      message.success(t('SECTION_DELETED'));
    }
  });

  const { mutateAsync: removeMaterial, isPending: isRemovingMaterial } = useMutation({
    mutationFn: (fileId: string) => CourseService.removeMaterialInFolder(fileId)
  });

  const { mutate: assignTeacherToCourse, isPending: isAssigning } = useMutation({
    mutationFn: (assign: IAssignTeacherToCourse) => CourseService.assignTeacherToCourse(assign),
    onSuccess: () => {
      onSuccess();
      message.success(t('COURSE_ASSIGNED_SUCCESSFULLY'));
    }
  });

  const {
    mutate: updateComplementaryMaterialTitle,
    isPending: isUpdatingComplementaryMaterialTitle
  } = useMutation({
    mutationFn: (values: IUpdateComplementaryMaterialTitle) =>
      CourseService.updateComplementaryMaterialTitle(values),
    onSuccess
  });

  const removeMaterialInFolder = async (
    courseId: string,
    folder: IFoldersWithMaterial,
    material: IFile[],
    onDone: () => void
  ) => {
    const promises = material.map(file => removeMaterial(file._id));
    try {
      await Promise.all(promises);
      updateFolder(
        {
          courseId: courseId,
          folder: {
            ...folder,
            material: folder.material?.filter(m => !material.includes(m))
          }
        },
        { onSuccess: onDone }
      );
    } catch (error) {
      console.error('Error removing material in folder:', error);
    }
  };

  const updateFolderName = async (
    courseId: string,
    folder: IFoldersWithMaterial,
    newName: string
  ) => {
    return updateFolder({
      courseId,
      folder: {
        ...folder,
        name: newName
      }
    });
  };

  const updateFolderMaterial = async (
    courseId: string,
    folder: IFoldersWithMaterial,
    newMaterial: IFile[]
  ) => {
    return updateFolder({
      courseId: courseId,
      folder: {
        ...folder,
        material: [...(folder.material || []), ...newMaterial]
      }
    });
  };

  const updateStatus = async (course: ICourse, status: ECourseStatus) => {
    return updateCourse({ ...course, status });
  };

  const { mutate: duplicateCourse, isPending: isDuplicatingCourse } = useMutation({
    mutationFn: (courseToDuplicate: ICourseToDuplicate) =>
      CourseService.duplicateCourse(courseToDuplicate),
    onSuccess: () => {
      onSuccess();
      message.success(t('COURSE_DUPLICATED_SUCCESSFULLY'));
    }
  });

  const {
    mutate: addComplementaryMaterialSection,
    isPending: isAddingComplementaryMaterialSection
  } = useMutation({
    mutationFn: (section: ICreateSection) => CourseService.addComplementaryMaterialSection(section),
    onSuccess: () => {
      onSuccess();
      message.success(t('SECTION_CREATED'));
    }
  });

  const { mutate: addIntroductionSection, isPending: isAddingIntroductionSection } = useMutation({
    mutationFn: (section: ICreateSection) => CourseService.addIntroductionSection(section),
    onSuccess: () => {
      onSuccess();
      message.success(t('SECTION_CREATED'));
    }
  });

  return {
    createCourse,
    updateCourse,
    removeCourse,
    reorderCourseSections,
    reorderCourseActivities,
    reorderCourseFolders,
    reorderCourseMaterial,
    moveMaterialBetweenFolders,
    removeMaterialInFolder,
    updateMaterialName,
    createFolder,
    updateFolderName,
    updateFolderMaterial,
    removeFolder,
    updateStatus,
    assignTeacherToCourse,
    duplicateCourse,
    addComplementaryMaterialSection,
    updateComplementaryMaterialTitle,
    addIntroductionSection,
    isWorking:
      isCreating ||
      isUpdating ||
      isRemoving ||
      isReorderingSections ||
      isReorderingActivities ||
      isReorderingFolders ||
      isReorderingMaterial ||
      isMovingMaterial ||
      isUpdatingMaterialName ||
      isCreatingFolder ||
      isUpdatingFolder ||
      isRemovingFolder ||
      isRemovingMaterial ||
      isAssigning ||
      isDuplicatingCourse ||
      isAddingComplementaryMaterialSection ||
      isUpdatingComplementaryMaterialTitle ||
      isAddingIntroductionSection
  };
};
