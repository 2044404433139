import { FC, useEffect, useState } from 'react';

import { StepWrapper } from '../../styles';

import { Wrapper } from './LoadingPlatform.styles';
import { PlanificationReady } from './PlanificationReady';
import { PlatformReady } from './PlatformReady';

import { useOnboardingLogic } from '@/hooks';
import { useMyPlanification } from '@/store';

export const LoadingPlatform: FC = () => {
  const [visibleText, setVisibleText] = useState(1);
  const { step, setIsPlatformReady } = useOnboardingLogic();
  const { planification } = useMyPlanification();

  useEffect(() => {
    if (step !== 12) return;
    setIsPlatformReady(false);
    setVisibleText(1);
    const timer1 = setTimeout(() => {
      setVisibleText(2);
    }, 2000);
    const timer2 = setTimeout(() => {
      setVisibleText(3);
      setIsPlatformReady(true);
    }, 5000);

    return () => {
      clearTimeout(timer1);
      clearTimeout(timer2);
    };
  }, [step]);

  return (
    <StepWrapper>
      <Wrapper>
        {planification ? (
          <PlanificationReady visibleText={visibleText} />
        ) : (
          <PlatformReady visibleText={visibleText} />
        )}
      </Wrapper>
    </StepWrapper>
  );
};
