import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 100%;
  display: flex;

  & > :first-child {
    width: 100%;
  }

  @media (min-width: ${({ theme }) => theme.mediaQueries.xs}) {
    & > :first-child {
      width: 65%;
      padding-right: 24px;
    }

    & > :last-child {
      width: 35%;
    }
  }
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;
