import { createQueryString } from '@utils';

import { RequestService } from '../request';

import {
  ICreateInternalNote,
  IInternalNote,
  IInternalNotesFilters,
  IUpdateInternalNote
} from './internal-note.contract';

export class InternalNoteService extends RequestService {
  constructor(apiUrl: string) {
    super(apiUrl);
  }

  async createInternalNote(newInternalNote: ICreateInternalNote) {
    return this.poster<IInternalNote, ICreateInternalNote>('/internal-notes', newInternalNote);
  }

  async findStudentInternalNotes(
    studentId?: string,
    search?: string,
    filters?: IInternalNotesFilters
  ) {
    const query = createQueryString({ search, filters });
    return this.fetcher<IInternalNote[]>(`/internal-notes/student/${studentId}?${query}`);
  }

  async updateInternalNote(newInternalNote: IUpdateInternalNote) {
    return this.putter<IInternalNote, IUpdateInternalNote>(
      `/internal-notes/${newInternalNote._id}`,
      newInternalNote
    );
  }

  async deleteInternalNote(internalNoteId: string) {
    return this.deleter(`/internal-notes/${internalNoteId}`);
  }
}
