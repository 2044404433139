/* eslint-disable indent */
import { EPostStatus } from '@constants';
import { dateProvider } from '@utils';
import { Button, Divider, Drawer, Flex, Image, Popconfirm, Popover, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

import { usePostForm, usePostViewer } from '../../../hooks';
import { RelatedPostsToPost } from '../RelatedPosts';

import {
  DateWrapper,
  FeaturedTag,
  ImageWrapper,
  PostContentWrapper,
  SubtitleWrapper,
  TitleWrapper
} from './PostViewer.style';

import { Icon } from '@/components/Icon';
import { useIsMobile } from '@/hooks';
import { useMutatePost, usePostDetail, useProfile } from '@/store';

type Props = {
  isEditable: boolean;
};

export const PostViewer: React.FC<Props> = ({ isEditable }) => {
  const { t } = useTranslation('CommonDashboardTranslations');
  const { postId, setPostId } = usePostViewer();
  const { setPost, setIsOpen } = usePostForm();
  const { removePost } = useMutatePost();
  const { post } = usePostDetail(postId);
  const { roleCheck } = useProfile();
  const isMobile = useIsMobile();

  const DRAWER_WIDTH = isMobile ? '100%' : 600;

  if (!post) return null;

  const {
    _id,
    title,
    topic,
    content,
    tags,
    status,
    isFeatured,
    updatedAt,
    featuredImage,
    scheduledPublishDate
  } = post;

  const { isManager, isAdmin, isTeacher } = roleCheck;
  const canEdit = isManager || isAdmin || isTeacher;

  const tagCount = tags?.length || 0;
  const tagsNames = tags?.map(tag => tag.name).join(', ');

  const isScheduled = canEdit && status === EPostStatus.SCHEDULED && scheduledPublishDate;

  const handleClose = () => {
    setPostId(undefined);
  };

  const handleEdit = () => {
    setPost(post);
    setIsOpen(true);
    handleClose();
  };

  const handleRemove = () => {
    removePost(post._id);
    handleClose();
  };

  return (
    <Drawer
      open={!!postId}
      width={DRAWER_WIDTH}
      onClose={handleClose}
      title={
        <Flex justify="flex-end">
          {isEditable && (
            <Flex align="flex-end">
              <Button type="link" size="small" icon={<Icon i="Edit" />} onClick={handleEdit} />
              <Popconfirm
                title={t('ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_POST')}
                onConfirm={handleRemove}>
                <Button type="link" size="small" icon={<Icon i="TrashCan" />} />
              </Popconfirm>
            </Flex>
          )}
        </Flex>
      }>
      {isFeatured && <FeaturedTag>{t('FEATURED_NEWS')}</FeaturedTag>}
      <Flex vertical gap={24}>
        <Flex vertical gap={14}>
          <TitleWrapper>{title}</TitleWrapper>
          <SubtitleWrapper>
            <DateWrapper isscheduled={isScheduled ? 'true' : 'false'}>
              <div>
                {isScheduled
                  ? dateProvider.format(
                      dateProvider.zonedTimeToUtc(scheduledPublishDate),
                      'dd MMM, yyyy'
                    )
                  : dateProvider.format(updatedAt, 'dd MMM, yyyy')}
              </div>
              {isScheduled && (
                <Tooltip
                  title={dateProvider.format(
                    dateProvider.zonedTimeToUtc(scheduledPublishDate),
                    'dd/MM/yyyy HH:mm'
                  )}>
                  <Icon i="Time" />
                </Tooltip>
              )}
            </DateWrapper>
            <span> • </span>
            {topic.name}
            {tagCount > 0 && (
              <>
                <span> • </span>
                <Popover title={t('TAGS')} content={<div>{tagsNames}</div>}>
                  <div>{t('POST_TAGS_COUNT', { count: tagCount })}</div>
                </Popover>
              </>
            )}
          </SubtitleWrapper>
        </Flex>
        <ImageWrapper>
          <Image width="100%" height={320} preview={false} alt={title} src={featuredImage?.url} />
        </ImageWrapper>
        <PostContentWrapper dangerouslySetInnerHTML={{ __html: content }} />
      </Flex>
      <Divider />
      <RelatedPostsToPost postId={_id} />
    </Drawer>
  );
};
