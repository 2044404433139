import styled from 'styled-components';

export const Wrapper = styled.div`
  & > :nth-child(2) {
    & > :first-child {
      margin-bottom: 30px;
    }
  }
`;

export const Title = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: space-between;
  @media (min-width: ${({ theme }) => theme.mediaQueries.md}) {
    flex-direction: row;
  }
`;
