import { Tag } from 'antd';
import styled from 'styled-components';

import BACKGROUND_PNG from '@/assets/bg_pattern.png';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 220px);
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.white};
`;

export const MessagesWrapper = styled.div`
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  padding: 0px 16px;
  border-top: 1px solid ${({ theme }) => theme.colors.black4};
  border-right: 1px solid ${({ theme }) => theme.colors.black4};
  border-left: 1px solid ${({ theme }) => theme.colors.black4};
  position: relative;
  background: url(${BACKGROUND_PNG}) lightgray 0% 0% / 20px 20px repeat;
`;

export const DayMessagesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const InputWrapper = styled.div`
  display: flex;
  width: 100%;
  border-right: 1px solid ${({ theme }) => theme.colors.black4};
  border-top: 1px solid ${({ theme }) => theme.colors.black4};
  border-bottom: 1px solid ${({ theme }) => theme.colors.black4};
  overflow-y: hidden;
  align-items: end;

  .ql-toolbar.ql-snow {
    border-top: none;
    border-right: none;
    border-left: none;
  }
  .ql-container.ql-snow {
    border: none;
  }

  .ql-editor {
    width: 90%;
  }

  .quill {
    width: 100%;
  }

  @media (min-width: ${({ theme }) => theme.mediaQueries.sm}) {
    .ql-editor {
      width: 95%;
    }
  }
`;

export const ButtonWrapper = styled.div`
  position: absolute;
  right: 4px;
  bottom: 4px;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-left: 1px solid ${({ theme }) => theme.colors.black4};
`;

export const PendingFileWrapper = styled(Tag)`
  position: relative;
  color: ${({ theme }) => theme.colors.link};
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 2px 4px;
`;

export const FileInputWrapper = styled.div<{ hasfile: boolean }>`
  display: flex;
  justify-content: ${({ hasfile }) => (hasfile ? 'space-between' : 'flex-end')};
  width: 100%;
  align-items: center;
  padding: 2px 12px;
  border-right: 1px solid ${({ theme }) => theme.colors.black4};
`;

export const RemoveFileWrapper = styled.div`
  position: absolute;
  right: -8px;
  top: -8px;
  color: ${({ theme }) => theme.colors.black9};
`;

export const EditorWrapper = styled.div`
  position: relative;
`;
