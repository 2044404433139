import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';

import { FooterWrapper, StyledButton } from './RestDaysConfirmationModal.styles';

type Props = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onConfirm: () => void;
};

export const RestDaysConfirmationModal: React.FC<Props> = ({ isOpen, setIsOpen, onConfirm }) => {
  const { t } = useTranslation('DefaultTranslations');
  const handleCancel = () => setIsOpen(false);

  const handleConfirm = () => {
    handleCancel();
    onConfirm?.();
  };

  return (
    <Modal
      open={isOpen}
      onCancel={handleCancel}
      centered
      title={t('EMPTY_REST_DAYS_TITLE')}
      footer={
        <FooterWrapper>
          <StyledButton block onClick={handleConfirm}>
            {t('I_NEVER_REST')}
          </StyledButton>
          <StyledButton block type="primary" onClick={() => setIsOpen(false)}>
            {t('CHOOSE_REST_DAYS')}
          </StyledButton>
        </FooterWrapper>
      }>
      <p>{t('EMPTY_REST_DAYS_SUBTITLE')}</p>
    </Modal>
  );
};
