import { IFile } from '@sdk/contracts';
import { Form, Flex, Tooltip, Button } from 'antd';
import { useTranslation } from 'react-i18next';

import { TitleWrapper } from './DeliverableType.styles';

import { Animated } from '@/components/Animated';
import { TeacherCourseSelector } from '@/components/Form';
import { Icon } from '@/components/Icon';
import { Upload } from '@/components/Upload';
import { useActivityForm } from '@/store';
import { IUpload } from '@/types';

type Props = {
  value?: { file: IFile };
  onChange?: ({ file }: { file: IUpload['value'] }) => void;
};

const UPLOAD_WIDTH = '100%';
const UPLOAD_HEIGHT = 250;

export const DeliverableType: React.FC<Props> = ({ value, onChange }) => {
  const { t } = useTranslation('CourseTranslations');
  const { course } = useActivityForm();

  const handleChange = (file: IUpload['value']) => onChange?.({ file });

  if (!course) return null;

  return (
    <Animated>
      <Flex vertical gap={24}>
        <Upload
          preview
          value={value?.file}
          width={UPLOAD_WIDTH}
          height={UPLOAD_HEIGHT}
          onChange={handleChange}
          accept="application/pdf">
          <div>
            <Icon i="TaskView" />
            <p>{t('ACTIVITY_DELIVERABLE_TYPE_DESCRIPTION')}</p>
          </div>
        </Upload>
        <Flex vertical gap={16}>
          <Flex gap={4}>
            <TitleWrapper>{t('ACTIVITY_DELIVERABLE_TYPE_ASSIGN')}</TitleWrapper>
            <Tooltip
              title={<span>{t('ACTIVITY_DELIVERABLE_TYPE_ASSIGN_INFO')}</span>}
              placement="top">
              <Button type="link" size="small" icon={<Icon i="Information" />} />
            </Tooltip>
          </Flex>
          <Form.Item name={'assignedTo'}>
            <TeacherCourseSelector teachers={course.teachers} allowClear />
          </Form.Item>
        </Flex>
      </Flex>
    </Animated>
  );
};
