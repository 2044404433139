/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form } from 'antd';
import { createContext, useContext, ReactNode } from 'react';

export const OnboardingFormContext = createContext<ReturnType<typeof Form.useForm<any>>[0] | null>(
  null
);

export const OnboardingFormProvider = ({ children }: { children: ReactNode }) => {
  const [form] = Form.useForm();
  return <OnboardingFormContext.Provider value={form}>{children}</OnboardingFormContext.Provider>;
};

export const useOnboardingForm = () => {
  const context = useContext(OnboardingFormContext);
  if (!context) {
    throw new Error('useOnboardingForm must be used within OnboardingFormProvider');
  }
  return context;
};
