import { ICreatePlanificationForm } from '@sdk/contracts';

import {
  Welcome,
  StudySteps,
  StudentInfo,
  DiscoverySource,
  ChosenReason,
  DoYouKnowOtherPeople,
  NotificationSettings,
  LoadingPlatform,
  ContractSign
} from '../components';

import {
  DoYouHaveExamDate,
  ExamDate,
  FilterSubscriptions,
  RestDays,
  StudyPlanIntro
} from '@/components/Planification';

export const createSlideItems = ({
  handleNextStep,
  planification,
  isRestDaysConfirmationOpen,
  setIsRestDaysConfirmationOpen
}: {
  handleNextStep: () => void;
  planification?: ICreatePlanificationForm;
  isRestDaysConfirmationOpen: boolean;
  setIsRestDaysConfirmationOpen: (value: boolean) => void;
}) => [
  <Welcome />,
  <StudySteps />,
  <StudentInfo />,
  <DiscoverySource />,
  <ChosenReason />,
  <DoYouKnowOtherPeople />,
  <NotificationSettings />,
  <StudyPlanIntro />,
  <DoYouHaveExamDate handleNext={handleNextStep} />,
  <ExamDate hasExamDate={Boolean(planification?.hasExamDate)} />,
  <RestDays
    isOpen={isRestDaysConfirmationOpen}
    setIsOpen={setIsRestDaysConfirmationOpen}
    onConfirm={handleNextStep}
  />,
  <FilterSubscriptions />,
  <LoadingPlatform />,
  <ContractSign />
];

export const TOTAL_SLIDES = 14;
