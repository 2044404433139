import { Flex, Typography } from 'antd';
import styled from 'styled-components';

type Props = {
  islocked: 'true' | 'false';
  iscompleted: 'true' | 'false';
};

type LockedProps = {
  islocked: 'true' | 'false';
};

export const Wrapper = styled(Flex)<LockedProps>`
  width: 100%;
  padding: 12px 16px;
  cursor: ${({ islocked }) => (islocked === 'true' ? 'not-allowed' : 'pointer')};

  &:hover {
    background: ${({ theme }) => theme.colors.black1};

    &:last-child {
      border-radius: 0 0 8px 8px;
    }
  }
`;

export const IconWrapper = styled(Flex)<Props>`
  padding: 10px;
  border-radius: 4px;
  background: ${({ theme, iscompleted }) =>
    iscompleted === 'true' ? theme.colors.green1 : theme.colors.black2};
  pointer-events: none;
`;

export const TitleWrapper = styled(Typography.Paragraph)<LockedProps>`
  font-family: ${({ theme }) => theme.fontFamily.openSans};
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: ${({ theme, islocked }) =>
    islocked === 'true' ? theme.colors.black5 : theme.colors.black9};
  margin-bottom: 0 !important;
`;

export const SubtitleWrapper = styled.span<LockedProps>`
  font-family: ${({ theme }) => theme.fontFamily.openSans};
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: ${({ theme, islocked }) =>
    islocked === 'true' ? theme.colors.black5 : theme.colors.black6};
`;

export const ActivityNotesWrapper = styled(Flex)`
  cursor: pointer;
  padding: 2px 4px;
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.white};

  color: ${({ theme }) => theme.colors.black5};
  font-family: ${({ theme }) => theme.fontFamily.openSans};
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;

  &:hover {
    background: ${({ theme }) => theme.colors.black2};
  }
`;

export const FavoriteWrapper = styled.div<{ show: 'true' | 'false' }>`
  opacity: ${({ show }) => (show === 'true' ? 1 : 0)};
  transition: opacity 0.2s ease;
  display: flex;
  cursor: pointer;
  width: 24px;
  height: 24px;
  padding: 2px 4px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.white};

  &:hover {
    background: ${({ theme }) => theme.colors.black2};
  }
`;
