import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;

  & > :first-child {
    width: 100%;
  }

  @media (min-width: ${({ theme }) => theme.mediaQueries.xs}) {
    & > :first-child {
      width: 80%;
      padding-right: 24px;
    }

    & > :last-child {
      width: 20%;
    }
  }
`;
