import { useEffect, useRef } from 'react';

interface ISignWellEmbed {
  url: string;
  events: {
    completed: (e: { id: string; url: string }) => void;
    closed: (e: { id: string }) => void;
    iframeLoaded?: (e: { id: string }) => void;
    documentLoaded?: (e: { id: string }) => void;
    error?: () => void;
  };
  open: () => void;
  close: () => void;
}

export const useSignWellScript = () => {
  const signWellEmbedRef = useRef<ISignWellEmbed | null>(null);
  const isContractLoadedRef = useRef(false);
  const isScriptLoadedRef = useRef(false);

  useEffect(() => {
    if (document.querySelector('script[src*="signwell.com/assets/embedded.js"]')) {
      isScriptLoadedRef.current = true;
      return;
    }

    const script = document.createElement('script');
    script.src = 'https://static.signwell.com/assets/embedded.js';
    script.type = 'text/javascript';
    script.async = true;

    script.onload = () => {
      isScriptLoadedRef.current = true;
    };

    script.onerror = () => {
      console.error('Error loading SignWell script');
      isScriptLoadedRef.current = false;
    };

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const openSignWell = (
    signUrl: string,
    options?: { onComplete?: () => void; onClose?: () => void }
  ) => {
    if (!isScriptLoadedRef.current || !window.SignWellEmbed || isContractLoadedRef.current) return;

    signWellEmbedRef.current = new window.SignWellEmbed({
      url: signUrl,
      containerId: 'contract-wrapper',
      events: {
        completed: _e => {
          options?.onComplete?.();
        },
        closed: _e => {
          options?.onClose?.();
        }
      },
      allowClose: false,
      allowDecline: false
    });

    isContractLoadedRef.current = true;
    signWellEmbedRef.current?.open();
  };

  const closeSignWell = () => {
    if (!isScriptLoadedRef.current || !window.SignWellEmbed || !isContractLoadedRef.current) return;
    signWellEmbedRef.current?.close();
  };

  return { isScriptLoaded: isScriptLoadedRef.current, openSignWell, closeSignWell };
};
