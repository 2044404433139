import { Flex, Typography } from 'antd';
import styled from 'styled-components';

const { Paragraph } = Typography;

export const Wrapper = styled(Flex)`
  width: 100%;

  .ant-badge-dot {
    left: -5px;
    right: auto;
    transform: translate(-50%, -50%);
  }
`;

type Props = {
  disabled: boolean;
};

export const TitleWrapper = styled.span<Props>`
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  color: ${({ theme, disabled }) => (disabled ? theme.colors.black5 : theme.colors.black9)};
  font-family: ${({ theme }) => theme.fontFamily.openSans};
`;

export const InfoWrapper = styled(Flex)`
  width: 100%;
`;

export const ProgressWrapper = styled(Flex)`
  width: 100%;

  .ant-progress-text-outer {
    display: none;
  }
`;

export const ProgressTextWrapper = styled.span`
  color: ${({ theme }) => theme.colors.black6};
  font-family: ${({ theme }) => theme.fontFamily.openSans};
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
`;

export const DescriptionWrapper = styled(Paragraph)`
  color: ${({ theme }) => theme.colors.black7};
  font-family: ${({ theme }) => theme.fontFamily.openSans};
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 0 !important;
`;
