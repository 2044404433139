import { ECourseDetailTab } from '@constants';
import { create } from 'zustand';

interface IStore {
  tab: ECourseDetailTab;
  handleTab: (tab: ECourseDetailTab) => void;
}

export const useCourseDetailTabs = create<IStore>(set => ({
  tab: ECourseDetailTab.ALL,
  handleTab: tab => set({ tab })
}));
