import { AnalyticsTrackEvents } from '@constants';
import { ICreateExamResult, IUpdateExamResult } from '@sdk/contracts';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';

import { useAnalytics } from '@/hooks';
import { sdk } from '@/services';

const ExamResultService = sdk.getService('ExamResultService');

export const useMutateExamResult = () => {
  const { t } = useTranslation('DefaultTranslations');
  const queryClient = useQueryClient();
  const { fireEvent } = useAnalytics();

  const onSuccess = () => queryClient.invalidateQueries({ queryKey: ['exam-results'] });

  const { mutate: createScore, isPending: isCreating } = useMutation({
    mutationFn: (create: ICreateExamResult) => ExamResultService.createExamResult(create),
    onSuccess: examResult => {
      onSuccess();
      message.success(t('SCORE_CREATED_SUCCESSFULLY'));
      fireEvent(AnalyticsTrackEvents.EXAM_SCORE, {
        score: examResult.score,
        IDstudent: examResult.user._id
      });
    }
  });

  const { mutate: updateScore, isPending: isUpdating } = useMutation({
    mutationFn: (update: IUpdateExamResult) => ExamResultService.updateExamResult(update),
    onSuccess: () => {
      onSuccess();
      message.success(t('SCORE_UPDATED_SUCCESSFULLY'));
    }
  });

  return {
    createScore,
    updateScore,
    isWorking: isCreating || isUpdating
  };
};
