import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const TitleText = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
`;

export const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

export const OptionWrapper = styled.div<{ isSelected: boolean }>`
  display: flex;
  padding: 16px;
  align-items: flex-start;
  border-radius: 4px;
  border: 1px solid
    ${({ theme, isSelected }) => (isSelected ? theme.colors.green6 : theme.colors.black4)};

  .ant-radio {
    align-self: baseline;
  }
  .ant-radio-checked .ant-radio-inner {
    border-color: ${({ theme }) => theme.colors.green6};
    background-color: transparent;
    border-width: 2px;
    :after {
      background-color: ${({ theme }) => theme.colors.green6};
    }
  }
  .ant-radio:hover .ant-radio-inner {
    border-color: ${({ theme }) => theme.colors.green6};
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
  margin: 10px 0px;
  gap: 8px;
  flex-direction: column;
`;

export const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
