import { Tag } from 'antd';
import styled from 'styled-components';

export const TextWrapper = styled.span`
  color: ${({ theme }) => theme.colors.black9};
  font-family: ${({ theme }) => theme.fontFamily.openSans};
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
`;

export const TagWrapper = styled(Tag)`
  margin-inline-end: 0 !important;
  border: 1px solid ${({ theme }) => theme.colors.black4} !important;
  background: ${({ theme }) => theme.colors.black3} !important;
`;
