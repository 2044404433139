import { IFile } from '@sdk/contracts';

import { Wrapper } from './EditorToolbar.styles';

import { Upload } from '@/components/Upload';

type Props = {
  isUploadDisabled: boolean;
  onFileUpload?: (file: IFile) => void;
};

export const EditorToolbar: React.FC<Props> = ({ isUploadDisabled, onFileUpload }) => {
  return (
    <Wrapper id="my-toolbar" className="my-toolbar">
      <select className="ql-header" defaultValue={''} onChange={e => e.persist()}>
        <option value="1" />
        <option value="2" />
        <option value="" />
      </select>
      <button className="ql-bold" />
      <button className="ql-italic" />
      <button className="ql-underline" />
      <button className="ql-strike" />
      <button className="ql-blockquote" />
      <button className="ql-list" value="ordered" />
      <button className="ql-list" value="bullet" />
      <button className="ql-attachment">
        <Upload
          disabled={isUploadDisabled}
          icon="Attachment"
          iconOnly
          shape="circle"
          mode="upload"
          showUploadList={false}
          buttonProps={{ size: 'small' }}
          onChange={f => onFileUpload?.(f as IFile)}
        />
      </button>
    </Wrapper>
  );
};
