import { AnalyticsTrackEvents } from '@constants';
import {
  ICreateActivity,
  IRemoveActivity,
  IRemoveTask,
  ITranscription,
  IUpdateActivity,
  IValidateTask,
  IUpdateActivityAuditStatus
} from '@sdk/contracts';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';

import { useAnalytics } from '@/hooks';
import { sdk } from '@/services';

const ActivityService = sdk.getService('ActivityService');

export const useMutateActivity = () => {
  const queryClient = useQueryClient();
  const onSuccess = () => queryClient.invalidateQueries({ queryKey: ['courses'] });
  const { fireEvent } = useAnalytics();
  const { t } = useTranslation('CourseTranslations');

  const { mutate: createActivity, isPending: isCreating } = useMutation({
    mutationFn: (create: ICreateActivity) => ActivityService.createActivity(create),
    onSuccess: (_, { courseName, activity }) => {
      onSuccess();
      message.success(t('CREATE_ACTIVITY_SUCCESS'));
      fireEvent(AnalyticsTrackEvents.TEACHER_ACTIVITY_NEW, {
        courseName,
        activityName: activity.name,
        activityDescription: activity.description,
        activityType: activity.type,
        attached: Boolean(activity.attachments?.length)
      });
    }
  });

  const { mutate: updateActivity, isPending: isUpdating } = useMutation({
    mutationFn: (update: IUpdateActivity) => ActivityService.updateActivity(update.activity),
    onSuccess: (_, { courseName, activity }) => {
      onSuccess();
      message.success(t('UPDATE_ACTIVITY_SUCCESS'));
      fireEvent(AnalyticsTrackEvents.TEACHER_ACTIVITY_EDITED, {
        courseName,
        activityType: activity.type
      });
    }
  });

  const { mutate: removeActivity, isPending: isRemoving } = useMutation({
    mutationFn: (remove: IRemoveActivity) => ActivityService.removeActivity(remove),
    onSuccess: () => {
      onSuccess();
      message.success(t('ACTIVITY_DELETED_SUCCESSFULLY'));
    }
  });

  const { mutate: removeTask, isPending: isRemovingTask } = useMutation({
    mutationFn: (remove: IRemoveTask) => ActivityService.removeTask(remove),
    onSuccess: () => {
      onSuccess();
      message.success(t('ACTIVITY_TASK_DELETED_SUCCESSFULLY'));
    }
  });

  const { mutate: validateTask, isPending: isValidatingTask } = useMutation({
    mutationFn: (validate: IValidateTask) => ActivityService.validateTask(validate),
    onSuccess: () => {
      onSuccess();
      message.success(t('ACTIVITY_TASK_VALIDATED_SUCCESSFULLY'));
    }
  });

  const { mutate: updateActivityAuditStatus, isPending: isUpdatingAuditStatus } = useMutation({
    mutationFn: (activity: IUpdateActivityAuditStatus) =>
      ActivityService.updateActivityAuditStatus(activity),
    onSuccess: () => {
      onSuccess();
      message.success(t('UPDATE_ACTIVITY_SUCCESS'));
    }
  });

  const { mutate: generateVideoCheckPoints, isPending: isGenerating } = useMutation({
    mutationFn: (transcription: ITranscription) =>
      ActivityService.generateVideoCheckPoints(transcription)
  });

  return {
    createActivity,
    updateActivity,
    updateActivityAuditStatus,
    removeActivity,
    removeTask,
    validateTask,
    generateVideoCheckPoints,
    isWorking:
      isCreating ||
      isUpdating ||
      isRemoving ||
      isGenerating ||
      isRemovingTask ||
      isValidatingTask ||
      isUpdatingAuditStatus
  };
};
