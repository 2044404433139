import { AnalyticsTrackEvents } from '@constants';
import {
  ICreateDeliverable,
  IDeliverable,
  ISendDeliverableMessage,
  ISetDeliverableReview
} from '@sdk/contracts';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';

import { useAnalytics } from '@/hooks';
import { sdk } from '@/services';

const DeliverableService = sdk.getService('DeliverableService');

export const useMutateDeliverables = () => {
  const { t } = useTranslation(['StudentCourseTranslations', 'DeliverableListTranslations']);
  const queryClient = useQueryClient();
  const { fireEvent } = useAnalytics();

  const onSuccess = () => {
    queryClient.invalidateQueries({ queryKey: ['deliverables'] });
  };

  const { mutate: createDeliverable, isPending: isCreating } = useMutation({
    mutationFn: (newDeliverable: ICreateDeliverable) =>
      DeliverableService.createDeliverable(newDeliverable),
    onSuccess: deliverable => {
      onSuccess();
      message.success(t('StudentCourseTranslations:DELIVERABLE_CREATED_SUCCESSFULLY'));
      fireEvent(AnalyticsTrackEvents.ACTIVITY_DELIVERABLE_UPLOAD, {
        deliverableName: deliverable.activity.name,
        courseName: deliverable.course.name
      });
    }
  });

  const { mutate: updateDeliverable, isPending: isUpdating } = useMutation({
    mutationFn: (deliverable: Partial<IDeliverable>) =>
      DeliverableService.updateDeliverable(deliverable),
    onSuccess: () => {
      onSuccess();
      message.success(t('DeliverableListTranslations:DELIVERABLE_HAS_BEEN_UPDATED'));
    }
  });

  const { mutate: setDeliverableReview, isPending: isReviewing } = useMutation({
    mutationFn: (deliverableReview: ISetDeliverableReview) =>
      DeliverableService.setDeliverableReview(deliverableReview),
    onSuccess: () => {
      onSuccess();
      message.success(t('DeliverableListTranslations:THANKS_FOR_YOUR_FEEDBACK'));
    }
  });

  const { mutate: removeDeliverable, isPending: isRemoving } = useMutation({
    mutationFn: (deliverableId: string) => DeliverableService.removeDeliverable(deliverableId),
    onSuccess: () => {
      onSuccess();
      message.success(t('DeliverableListTranslations:DELIVERABLE_REMOVED_SUCCESSFULLY'));
    }
  });

  const { mutate: sendMessage, isPending: isSending } = useMutation({
    mutationFn: (sendDeliverableMessage: ISendDeliverableMessage) =>
      DeliverableService.sendMessage(sendDeliverableMessage),
    onSuccess
  });

  return {
    createDeliverable,
    updateDeliverable,
    setDeliverableReview,
    removeDeliverable,
    sendMessage,
    isWorking: isCreating || isUpdating || isReviewing || isRemoving || isSending
  };
};
