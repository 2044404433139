import { useQuery } from '@tanstack/react-query';

import { useStudentsStatsList } from './useStudentsStatsList';

import { sdk } from '@/services';

const UserService = sdk.getService('UserService');

export const useDownloadStudentStats = () => {
  const {
    filter,
    sort,
    search,
    page,
    limit,
    pagination,
    isLoading: isLoadingList
  } = useStudentsStatsList();

  const { data, error, isLoading, refetch } = useQuery({
    queryKey: ['students', 'stats', search, page, limit, filter, sort, pagination?.totalDocs],
    queryFn: () =>
      UserService.downloadStudentsStats(
        search,
        { page, limit: pagination?.totalDocs ?? 999999 },
        filter,
        sort
      ),
    enabled: false
  });

  const downloadStudentStats = async () => {
    const result = await refetch();
    return result.data;
  };

  return { downloadStudentStats, data, error, isLoading: isLoadingList || isLoading };
};
