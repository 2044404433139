import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  flex-wrap: wrap;

  .ql-attachment {
    border: none !important;
    padding: 0 !important;
  }

  .ant-btn-default:not(.ant-btn-dangerous):not(.ant-input-search-button) {
    border: none !important;

    &:hover {
      border: none !important;
    }
    &:focus {
      border: none !important;
    }
  }
`;
