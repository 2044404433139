import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  align-self: stretch;
  width: 100%;
`;

export const ItemsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 4px 8px;
  gap: 8px;
  overflow-y: auto;

  .ant-divider {
    margin: 0;
  }

  @media (min-width: ${({ theme }) => theme.mediaQueries.xs}) {
    padding: 12px 0px 0px 16px;
  }
`;

export const FiltersWrapper = styled.div`
  padding: 4px 8px;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.black3};

  @media (min-width: ${({ theme }) => theme.mediaQueries.xs}) {
    padding: 0px 0px 12px 24px;
  }
`;
