import { Form } from 'antd';
import { useTranslation } from 'react-i18next';

import { StudyPlanBadge } from '../StudyPlanBadge';

import { DailyHoursPicker } from './DailyHoursPicker';
import { DailyMinutesPicker } from './DailyMinutesPicker';
import { HeaderWrapper, ItemsWrapper, Wrapper } from './ExamDate.styles';
import { ExamDatePicker } from './ExamDatePicker';

type Props = {
  hasExamDate: boolean;
};

export const ExamDate: React.FC<Props> = ({ hasExamDate }) => {
  const { t } = useTranslation('DefaultTranslations');

  return (
    <Wrapper>
      <HeaderWrapper>
        <StudyPlanBadge />
        <h2>{t(hasExamDate ? 'CAN_YOU_TELL_US_WHEN' : 'HOW_LONG_WILL_YOU_STUDY')}</h2>
        <p>
          {t(hasExamDate ? 'CAN_YOU_TELL_US_WHEN_SUBTITLE' : 'HOW_LONG_WILL_YOU_STUDY_SUBTITLE')}
        </p>
      </HeaderWrapper>
      {hasExamDate ? (
        <Form.Item name={['planification', 'endDate']}>
          <ExamDatePicker />
        </Form.Item>
      ) : (
        <ItemsWrapper>
          <Form.Item name={['planification', 'studyHours']}>
            <DailyHoursPicker />
          </Form.Item>
          <Form.Item name={['planification', 'studyMinutes']}>
            <DailyMinutesPicker />
          </Form.Item>
        </ItemsWrapper>
      )}
    </Wrapper>
  );
};
