import { ERoles } from '@constants';
import { ILoginRequest } from '@sdk/contracts';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { sdk } from '@/services';

const AuthService = sdk.getService('AuthService');

export const useLogin = (redirectPath?: string) => {
  const [searchParams] = useSearchParams();
  const navigateTo = useNavigate();
  const queryClient = useQueryClient();

  const { mutate: login, isPending } = useMutation({
    mutationFn: (loginData: ILoginRequest) => AuthService.login(loginData),
    onSuccess: ({ user }) => {
      queryClient.setQueryData(['profile'], user);

      const returnUrl = searchParams.get('return_url');
      if (returnUrl) return (window.location.href = returnUrl);

      if (redirectPath) return navigateTo(redirectPath, { replace: true });
      const isStudent = user.role === ERoles.STUDENT;
      const path = user.contract?.isContractSigned || !isStudent ? '/dashboard' : '/onboarding';
      navigateTo(path, { replace: true });
    }
  });

  return { login, isWorking: isPending };
};
